import styles from "./mainVideo.module.scss";
import Vimeo from "@u-wave/react-vimeo";
import { useState, useEffect, useRef } from "react";
import LoadingSpinner from "../spinner/spinner";
import { Link } from "react-router-dom";

const MainVideo = () => {
  const [loading, setLoading] = useState(true);

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const breakpoint = 800;
  const handleIfrmeLoad = () => {
    setLoading(false);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "1em",
        marginTop: width > breakpoint ? "-4em" : 0,
      }}
    >
      <div
        style={
          width > breakpoint
            ? {
                width: "60%",
                padding: "5em",
              }
            : { width: "100%", padding: "2em" }
        }
      >
        {}
        <div className={styles.embedContainer}>
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              src="https://player.vimeo.com/video/811495499?h=de0648c30f&autoplay=1&title=0&loop=1&byline=0&portrait=0&controls=0&muted=1"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainVideo;
