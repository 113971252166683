import "./App.css";
import NavBar from "./components/navBar/navBar/navBar";
import Home from "./routes/home";
import Footer from "./components/footer/footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Videos from "./routes/videos";
import Detail from "./routes/detail";
import { useState } from "react";
import ErrorPage from "./routes/errorPage";
import News from "./routes/news";
import MoviesSeries from "./routes/Movies&Series";
import { HashRouter } from "react-router-dom";

function App() {
  const [scrollTo, setScrollTo] = useState("");

  return (
    <div className="App">
      <HashRouter>
        <NavBar setScrollTo={setScrollTo} />
        <Routes>
          <Route
            path="/"
            element={<Home scrollTo={scrollTo} setScrollTo={setScrollTo} />}
          />
          <Route path="/news" element={<News />} />
          <Route path="/director" element={<Videos />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/video/:detail" element={<Detail />} />
          <Route path="/*" element={<ErrorPage />} />
          <Route path="/movies&series" element={<MoviesSeries />} />
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
