import vimeo from "../../assets/images/vimeo.png";
import imdb from "../../assets/images/imdb.png";
import youtube from "../../assets/images/youtube.png";
import instagram from "../../assets/images/instagram.png";

import styles from "./contactLinks.module.scss";

const ContactLinks = () => {
  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <a target="_blank" href="https://vimeo.com/marianodawidson">
          <img className={styles.img} src={vimeo} alt="vimeo logo" />
        </a>
        <a
          target="_blank"
          href="https://www.imdb.com/name/nm2300625/?ref_=fn_al_nm_1"
        >
          <img className={styles.img} src={imdb} alt="imdb logo" />
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/@MARIANODAWIDSONdirector"
        >
          <img className={styles.img} src={youtube} alt="youtube logo" />{" "}
        </a>
        <a
          target="_blank"
          href="https://instagram.com/mariano_dawidson?igshid=OTJlNzQ0NWM="
        >
          <img className={styles.img} src={instagram} alt="instagram logo" />
        </a>
      </div>
    </div>
  );
};

export default ContactLinks;
