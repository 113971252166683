import React, { useState } from "react";

const LanguageContext = React.createContext();

const defaultLanguage = {
  language: true,
};

export const LanguageProvider = ({ children, language }) => {
  const [currentLanguage, setCurrentLanguage] = useState(
    language || defaultLanguage
  );

  const saveLanguage = (values) => {
    setCurrentLanguage(values);
  };

  return (
    <LanguageContext.Provider
      value={{ language: currentLanguage, saveLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const LanguageConsumer = LanguageContext.Consumer;

export default LanguageContext;
