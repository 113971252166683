import styles from "./videoCard.module.scss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const VideoCard = ({ title, link, img }) => {
  const [hover, setHover] = useState(false);
  const [randomElement, setRandomElement] = useState();

  function separarCadena(title) {
    const indiceGuion = title.indexOf("-");
    if (indiceGuion !== -1) {
      const parte1 = title.slice(0, indiceGuion).trim();
      const parte2 = title.slice(indiceGuion + 1).trim();
      return [parte1, parte2];
    } else {
      // Si no se encuentra ningún guion, devuelve la cadena original en la primera parte y una cadena vacía en la segunda parte
      return [title, ""];
    }
  }
  const partes = separarCadena(title);

  const array = [100, 150, 180];
  useEffect(() => {
    setRandomElement(array[Math.floor(Math.random() * array.length)]);
  }, []);

  return (
    <Link to={`/video/${title}`}>
      <div
        className={styles.container}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <img src={img} style={{ height: "auto", width: "350px" }} />
        {/*hover && (
          <div className={styles.titleBox}>
            <p className={styles.title}>{title}</p>
          </div>
        ) */}{" "}
        <div className={styles.titleBox2}>
          {" "}
          <p className={styles.title2}>
            {" "}
            <span className={styles.title3}>
              {partes[0].toUpperCase()}
            </span>{" "}
            <br />
            {partes[1]}
          </p>{" "}
        </div>{" "}
      </div>
    </Link>
  );
};

export default VideoCard;
