import { useState, useEffect } from "react";
import econSanMartin from "../assets/images/moviesSeries/econSanMartin.jpg";

const MoviesSeriesCardEcos = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const breakpoint = 800;
  return width > breakpoint ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginBottom: 200,
        borderTopWidth: 1,
        borderTopColor: "#ebb419",
        borderTopStyle: "groove",
        borderStyle: "groove",
        borderColor: "#ebb419",
        padding: "2em",
      }}
    >
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Ecos del san martín
      </h1>
      <span style={{ textAlign: "center", marginBottom: 16 }}> </span>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginTop: "5em",
        }}
      >
        <div style={{ width: "50%", paddingRight: "16px", textAlign: "left" }}>
          <p>
            Sinopsis: Las paredes guardan historias, ahí, al alcance de quien
            quiera detenerse para escucharlas. Por eso no es casual que quien
            habite este edificio esté acostumbrado a convivir con ellas, y con
            los ecos de sus personajes protagónicos. Un recorrido plástico por
            las venas del teatro General San Martín en su 60 aniversario, en 6
            episodios.
          </p>
          <p>
            <br />
            Ficha Técnica: <br />
            De Emiliano Dionisi
            <br />
            Dirección audiovisual de Mariano Dawidson
            <br />
            Elenco: Lucía Bayá Casal, Emiliano Carrazzone, Ana Yovino, Marcelo
            Pozzi, Laura Oliva, Leonardo Saggese Con la actuación especial de:
            Eleonora Wexler, Paola Barrientos, Esteban Meloni, Roberto Carnaghi,
            Leonor Manso, Luis Machín Y la participación del Ballet
            Contemporáneo y el Grupo de Titiriteros del Teatro San Martín
            <br />
          </p>{" "}
          <p>
            EQUIPO CREATIVO
            <br />
            Edición de imagen, colorimetría y montaje, Mariano Dawidson
            <br />
            Diseño de escenografía y dirección de arte, Flores Tucci
            <br />
            Diseño de vestuario, Marisol Castañeda
            <br />
            Diseño de iluminación, Patricio Pérez Duó
            <br />
            Música original, Martín Rodríguez
            <br />{" "}
          </p>{" "}
          <p>
            PRODUCCIÓN CTBA
            <br />
            Asistencia de dirección y coordinación de escenarios, Julián Castro,
            Lucas Pulido
            <br />
            Producción técnica, Tamara Gutierrez, Ángel Porro
            <br />
            Coordinación de producción, Constanza Comune Páez
            <br />
            Dirección, Emiliano Dionisi y Mariano Dawidson
          </p>
        </div>
        <div
          style={{
            width: "50%",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            marginBottom: 32,
          }}
        >
          {" "}
          <img
            src={econSanMartin}
            width={"100%"}
            height={"auto"}
            alt="img"
          />{" "}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            minHeight: "50vh",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 16,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <iframe
              src="https://player.vimeo.com/video/511107723?h=bba95c9c6c"
              width="48%"
              height="360"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>

            <iframe
              src="https://player.vimeo.com/video/511303977?h=ce82829e7a"
              width="48%"
              height="360"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>{" "}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <iframe
              src="https://player.vimeo.com/video/511108515?h=c8f32d875f"
              width="48%"
              height="360"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>

            <iframe
              src="https://player.vimeo.com/video/511342345?h=ad1de7ed3f"
              width="48%"
              height="360"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>{" "}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <iframe
              src="https://player.vimeo.com/video/511342355?h=22927bcba6"
              width="48%"
              height="360"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>

            <iframe
              src="https://player.vimeo.com/video/511342352?h=5a515a168c"
              width="48%"
              height="360"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginBottom: 100,
        borderBottomStyle: "groove",
        borderBottomStyle: "groove",
        borderBottomColor: "#ebb419",
        padding: "2em",
      }}
    >
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Ecos del san martín
      </h1>
      <span style={{ textAlign: "center", marginBottom: 16 }}> </span>{" "}
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          display: "flex",
          marginBottom: 32,
          marginTop: "5em",
        }}
      >
        {" "}
        <img
          src={econSanMartin}
          width={"100%"}
          height={"auto"}
          alt="img"
        />{" "}
      </div>
      <div style={{ width: "100%", textAlign: "justify" }}>
        {" "}
        <p>
          Sinopsis: Las paredes guardan historias, ahí, al alcance de quien
          quiera detenerse para escucharlas. Por eso no es casual que quien
          habite este edificio esté acostumbrado a convivir con ellas, y con los
          ecos de sus personajes protagónicos. Un recorrido plástico por las
          venas del teatro General San Martín en su 60 aniversario, en 6
          episodios.
        </p>
        <p>
          <br />
          Ficha Técnica: <br />
          De Emiliano Dionisi
          <br />
          Dirección audiovisual de Mariano Dawidson
          <br />
          Elenco: Lucía Bayá Casal, Emiliano Carrazzone, Ana Yovino, Marcelo
          Pozzi, Laura Oliva, Leonardo Saggese Con la actuación especial de:
          Eleonora Wexler, Paola Barrientos, Esteban Meloni, Roberto Carnaghi,
          Leonor Manso, Luis Machín Y la participación del Ballet Contemporáneo
          y el Grupo de Titiriteros del Teatro San Martín
          <br />
        </p>{" "}
        <p>
          EQUIPO CREATIVO
          <br />
          Edición de imagen, colorimetría y montaje, Mariano Dawidson
          <br />
          Diseño de escenografía y dirección de arte, Flores Tucci
          <br />
          Diseño de vestuario, Marisol Castañeda
          <br />
          Diseño de iluminación, Patricio Pérez Duó
          <br />
          Música original, Martín Rodríguez
          <br />{" "}
        </p>{" "}
        <p>
          PRODUCCIÓN CTBA
          <br />
          Asistencia de dirección y coordinación de escenarios, Julián Castro,
          Lucas Pulido
          <br />
          Producción técnica, Tamara Gutierrez, Ángel Porro
          <br />
          Coordinación de producción, Constanza Comune Páez
          <br />
          Dirección, Emiliano Dionisi y Mariano Dawidson
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: 32,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            minHeight: "300px",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: 32,
            marginBottom: 120,
          }}
        >
          <iframe
            src="https://player.vimeo.com/video/511107723?h=bba95c9c6c"
            width="100%"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            src="https://player.vimeo.com/video/511303977?h=ce82829e7a"
            width="100%"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            src="https://player.vimeo.com/video/511108515?h=c8f32d875f"
            width="100%"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            src="https://player.vimeo.com/video/511342345?h=ad1de7ed3f"
            width="100%"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            src="https://player.vimeo.com/video/511342355?h=22927bcba6"
            width="100%"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            src="https://player.vimeo.com/video/511342352?h=5a515a168c"
            width="100%"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default MoviesSeriesCardEcos;
