import useLanguage from "../hooks/useLanguage";
import styles from "./routes.module.scss";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  const { language } = useLanguage();
  return (
    <div
      style={{
        marginTop: "5em",
        alignContent: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={styles.container}
    >
      {!language ? (
        <h1> Ups ! No encontramos lo que estas buscando ! :( </h1>
      ) : (
        <h1> Ups ! We did not find what you are looking for ! :( </h1>
      )}
      <Link label="Home" to={"/"}>
        <h2> Home</h2>{" "}
      </Link>
    </div>
  );
};

export default ErrorPage;
