import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import videos from "../videoObjects";
import styles from "./routes.module.scss";
import { useNavigate } from "react-router-dom";

const Detail = () => {
  const [video, setVideo] = useState();
  const { detail } = useParams();
  const navigate = useNavigate();

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const breakpoint = 800;
  const detailUpperCase = detail.charAt(0).toUpperCase() + detail.slice(1);

  useEffect(() => {
    if (detail === "SHOWREEL") {
      setVideo({
        title: "SHOWREEL",
        video: "https://player.vimeo.com/video/811495499",
        cat: "reel",
      });
    } else {
      const result = videos.find((v) => v.title === detail);
      if (result) {
        setVideo(result);
      } else {
        navigate("/error");
      }
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginBottom: "2em",
        padding: "1em",
      }}
    >
      <div
        style={
          width > breakpoint
            ? { width: "60%", padding: "5em" }
            : { width: "100%", padding: "2em" }
        }
      >
        <div className={styles.titleBox}>
          <h1 className={styles.title}> {detailUpperCase}</h1>
        </div>
        <div className={styles.embedContainer}>
          {video && (
            <iframe
              src={video.video}
              target="_parent"
              frameborder="0"
              allowfullscreen
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default Detail;
