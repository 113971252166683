import MainVideo from "../components/mainVideo/mainVideo";
import About from "../components/about/about";
import ContactForm from "../components/form/form";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Home = ({ scrollTo, setScrollTo }) => {
  useEffect(() => {
    const element = document.getElementById(scrollTo);
    console.log("scrollTo", element);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setScrollTo("");
    }
  }, [scrollTo]);
  return (
    <div>
      <MainVideo />
      <Link to={`/video/SHOWREEL`}>
        <div
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: -55,
          }}
        >
          <h4>SHOWREEL</h4>
        </div>
      </Link>
      <div id="About">
        <About />
      </div>
      <div id="Contact">
        <ContactForm />
      </div>
    </div>
  );
};

export default Home;
