import styles from "./routes.module.scss";
import econSanMartin from "../assets/images/moviesSeries/econSanMartin.jpg";
import laSangre from "../assets/images/moviesSeries/laSangreDelGallo.jpg";
import CieloGrande from "../assets/images/moviesSeries/CieloGrande.jpg";
import LaCasa from "../assets/images/moviesSeries/laCasaAcecha.png";
import LaIraDeDios from "../assets/images/moviesSeries/LaIraDeDios.jpg";
import Matrimillas from "../assets/images/moviesSeries/Matrimillas.jpg";
import CañoDorado from "../assets/images/moviesSeries/CañoDorado.jpg";
import Kryptonita from "../assets/images/moviesSeries/Kryptonita.jpg";
import LaValija from "../assets/images/moviesSeries/laValija.jpg";
import MoviesSeriesCard from "../components/MoviesSeriesCard";
import soyCiego from "../assets/images/moviesSeries/soyCiego.jpg";
import MoviesSeriesCardEcos from "../components/MoviesSeriesCardEcos";
import { useEffect, useState } from "react";
const MoviesSeries = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  const breakpoint = 800;

  const dataArray = [
    {
      title: "La sangre del gallo",
      link: "https://player.vimeo.com/video/131886267?h=f5228c30ef",
      img: laSangre,
      body: (
        <>
          <p>
            “Un hombre despierta malherido en un lugar misterioso, y no puede
            recordar cómo llegó hasta allí. Poco a poco, imágenes de un
            accidente van llegando a su memoria.”
          </p>
          <p> Fecha de estreno inicial: 31 de octubre de 2015 </p>
          <p>Director: Mariano Dawidson </p>
          <p> Productor ejecutivo: Eric Dawidson</p>
          <p>Estreno en el festival intencional de mar del plata en 2019</p>
          <p>
            {" "}
            plataformas Starz estados unidos / Netflix World Wide / amazon prime
            world wide
          </p>{" "}
        </>
      ),
      order: 2,
    },
    {
      title: "Cielo grande 2",
      sub: "Dirección las escenas de acción",
      link: "https://player.vimeo.com/video/831206992?h=659478a098",
      img: CieloGrande,
      body: (
        <>
          Dirección las escenas de acción
          <p>
            Cielo grande es una serie web musical argentina de Netflix creada
            por Jorge Edelstein y producida por Non Stop.​ La trama sigue a un
            grupo de amigos que intentarán salvar de la quiebra a un parque
            acuático especializado en wakeboard. Está protagonizada por Pilar
            Pascual, Abril Di Yorio, Víctor Varona, Guido Messina, Francisco
            Bass, Giulia Guerrini, Thaís Rippel, Luan Brum, Fernando Monzo, Juan
            Monzo, Agustín Pardella, Mariel Percossi, Byron Barbieri, Martín
            Tecchi y Débora Nishimoto.​
          </p>
          <p>
            {" "}
            Sinopsis: Un grupo de jóvenes trabaja para salvar un hotel llamado
            Cielo grande especializado en deportes acuáticos como el rafting y
            perdido en el medio del delta argentino, donde una atleta mexicana
            buscará la verdad sobre su familia. En el camino la vida de esta
            chica, su entrenador y la de los chicos de cielo grande se
            entrelazaran creando un vínculo más poderoso que cualquier cosa,
            incluidos los romances y secretos.
          </p>
        </>
      ),
      order: 3,
    },
    {
      title: "La casa acecha",
      sub: " co-dirección junto a eric dawidson",
      link: "https://www.youtube.com/embed/fT5LF1AaHlo",
      img: LaCasa,
      body: (
        <>
          <p>
            Sinópsis: un personaje siniestro protege una casa abandonada
            llevando al hombre de la pareja a la locura y la muerte.
            ​​habitación 152o producciones & claudio braslavsky presentan a :
            mike amigorena y leonora balcarce en "la casa acecha" con edgardo
            moreira y christian alvarez. Guión dieguillo fernández sobre una
            idea de mariano de rosa asistencia de dirección: fernando vivas jefe
            de producción: andrea braga dirección de producción: miranda de
            souza sonido:celeste palma dirección de fotografía: daniel mendoza
            dirección de arte: alejandra soler & karina carbonato montaje:
            miguel colombo & gustavo codela producción ejecutiva: maxi dubos
            productor: maxi dubois dirección: eric dawidson y mariano dawidson
          </p>
          <p>
            Género: Fantástico y Suspenso.
            <br /> Duración: 77 min.
            <br /> Año: 2019.
            <br /> Guión: Dieguillo Fernandez - Mariano De Rosa - Alberto Fasce.
            <br /> Música: Rodrigo Crespo. <br />
            Director de sonido: Celeste Palma.
            <br /> Montaje: Gustavo Codella & Miguel Colombo. <br />
            Director de arte: Alejandra Isler.
            <br />
            Productores: Maximiliano Dubois / Ignacio Bollini / Miranda de Sá
            Souza. <br />
            Casa productora: Habitación 1520 Producciones SRL.
            <br /> Vestuario: Betiana Temkin.
            <br /> Director de animación: Derek Dawidson.
            <br /> Colorista: Hnos. Dawidson Films.
            <br /> Maquillaje: Mariela Aracena.
            <br /> Coordinación de posproducción: Matias Fernandez.
            <br /> Productores ejecutivos: Maximiliano Dubois & Miranda de Sá
            Souza.
            <br /> FX: Hnos. Dawidson Films.
            <br /> Premio: Proyecto Ganador del Concurso Cine Fantástico 2015.
          </p>
        </>
      ),
      order: 4,
    },
    {
      title: "Matrimillas ",
      sub: "consultoría de montaje",
      link: "https://www.youtube.com/embed/8WurH_dqrS0",
      img: Matrimillas,
      body: (
        <>
          <p>
            “ Una pareja joven con hijos se encuentra transitando una crisis y
            parecen haber agotado todas las instancias para poder resolver sus
            problemas de pareja. Como última solución, deciden utilizar una
            aplicación que les suma o resta puntos de acuerdo al mérito que
            vayan haciendo el uno con el otro. En un principio, todo parece
            funcionar perfectamente, pero la obsesión por acumular puntos y
            ganar independencia, hará que sus vidas terminen completamente fuera
            de control.”
          </p>
          <p>
            creditos
            <br />
            director: Seba de Caro
            <br />
            productor: Bufalo Films.
          </p>
        </>
      ),
      order: 5,
    },
    {
      title: "La ira de Dios ",
      sub: "consultoría de montaje",
      link: "https://www.youtube.com/embed/rCl3iDhNdms",
      img: LaIraDeDios,
      body: (
        <>
          <p>
            Macarena Achaga es Luciana, quien está inmersa en un círculo de
            misteriosas muertes de sus familiares, que se torna cada vez más
            pequeño alrededor suyo. Busca revelar la verdad detrás de las
            muertes con la ayuda del periodista Esteban, interpretado por Juan
            Minujín. Un enigmático escritor, su antiguo jefe (Diego Peretti),
            sobrevuela la escena con un velo de horror y aires de culpabilidad.
            La lucha obsesiva para salvar la vida de su única familiar viva, su
            hermana menor Valentina. Una encrucijada entre la razón y la muerte.
            Una carrera contra el tiempo para sacar a la luz su verdad, y un
            último pacto de sangre para terminar con la venganza.
          </p>
          <p>
            estreno el 15 de junio de 2022 en Netflix.
            <br />
            director: sebastian Schindel
            <br />
            Productora Bufalo Films
          </p>
        </>
      ),
      order: 6,
    },
    {
      title: "Caño dorado",
      sub: "montaje y correccion de color",
      link: "https://www.youtube.com/embed/T_3v5EZdV40",
      img: CañoDorado,
      body: (
        <>
          <p>
            Escrita y dirigida por Eduardo Pinto y producida por No Problem
            Cine. Director de fotografía Daniel Ortega, sonido No Problem,
            dirección de arte Sergui Hernandez, jefe de producción Cadi Martín,
            montaje y corrección de color Mariano Dawidson, productores
            ejecutivos Adela Rodriguez Larreta y Nico Batle, productor general
            Omar jadur. Buenos Aires - Argentina - 2009
          </p>
        </>
      ),
      order: 7,
    },
    {
      title: "Soy ciego documental",
      sub: "work in progress",
      link: "https://player.vimeo.com/video/209433486?h=b8f65b0c1a",
      img: soyCiego,
      body: (
        <>
          <p>
            El proyecto consiste en la realización de un largometraje-documental
            sobre la vida de Alexis Acosta, quien luego de quedar sin visión en
            su adolescencia, comenzó a entrenar hasta convertirse en uno de los
            mejores atletas ciegos del mundo. El documental retrata el esfuerzo
            y los logros camino al Mundial de Atletismo de Londres 2017 y sus
            resultados en las competencias. El film será estrenado en salas de
            todo el país, recorrerá festivales nacionales e internacionales y
            llegará a las grandes cadenas de V.O.D. como Netflix.
          </p>
        </>
      ),
      order: 8,
    },
    {
      title: "Kryptonita",
      sub: "correccion de color",
      link: "https://www.youtube.com/embed/mWaTWMouW3g",
      img: Kryptonita,
      body: (
        <>
          <p>
            Adaptación de la exitosa novela de Leonardo Oyola.
            <br /> Dirigida por Nicanor Loreti.
            <br /> Director de fotografía Mariano Suarez
          </p>
          <p>
            Con Juan Palomino, Diego Velázquez, Pablo Rago, Diego Cremonesi,
            Nicolás Vázquez, Carca, Sofía Palomino, Lautaro Delgado, Susana
            Varela, Pablo Pinto, Sebastián De Caro y la participación especial
            de Diego Capusotto.
          </p>
        </>
      ),
      order: 9,
    },
    {
      title: "La valija de Benavides ",
      sub: "correccion de color",
      link: "https://www.youtube.com/embed/A9I8VtqVszo",
      img: LaValija,
      body: (
        <>
          <p>
            dirigida por Laura Casabé y con actuaciones de Norma Aleandro, Jorge
            Marrale y Guillermo Pfening se estrenará el próximo 26 de enero.
          </p>
          <p>
            Se trata de una comedia negra y un thriller fantástico a la vez. En
            el film, Benavidez es un profesor de plástica, casado con una joven
            promesa de la pintura. Una noche discuten y él carga su valija y
            sale a buscar asilo a la casa de su psiquiatra, también
            coleccionista de arte. Al día siguiente descubre el secreto que
            oculta ese lugar: una residencia de artistas que participan de un
            tratamiento diseñado para ampliar su espectro creativo. Atrapado en
            la casa, lo someterán al tratamiento y en el intento de escapar,
            recorrerá distintas habitaciones y en ellas la historia de su vida,
            la relación con su padre y su esposa, para encontrarse al final del
            camino con una revelación que cambia su destino. La valija de
            Benavídez está basada en el cuento homónimo de Samanta Schweblin del
            libro El núcleo del disturbio.
          </p>
        </>
      ),
      order: 10,
    },
  ];

  return (
    <div
      className={styles.container}
      style={width < breakpoint ? { padding: 16 } : null}
    >
      <h1
        style={{
          marginBottom: "2em",
          fontSize: "4em",
          textAlign: "center",
        }}
      >
        {" "}
        Movies & Series
      </h1>
      <MoviesSeriesCardEcos />
      {dataArray.map((data) => {
        return <MoviesSeriesCard props={data} />;
      })}
    </div>
  );
};

export default MoviesSeries;
