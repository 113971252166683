import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import styles from "./form/form.module.scss";
import useLanguage from "../hooks/useLanguage";
const customStyles = {
  overlay: {
    background: "rgb(0,0,0,0.4)",
  },
  content: {
    top: "50%",
    left: "30%",
    color: "white",
    //transform: "translate(-50%, -50%)",
    backgroundColor: "black",
    width: "40%",
    height: "20%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement(document.getElementById("root"));

const CustomModal = (props) => {
  const { language } = useLanguage();
  const { modalOpen, setModalOpen, error, setError } = props;
  const close = () => {
    setModalOpen(false);
    setError(false);
  };

  return (
    <div
      style={{
        backgroundColor: "blue",
        justifyContent: "center",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Modal
        isOpen={modalOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
        onRequestClose={modalOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 style={{ color: "white", textAlign: "center" }}>
          {!error &&
            (language
              ? "Your message has been sent successfully"
              : "Tu mensaje se envio correctamente")}
          {error &&
            (language
              ? "There was a problem sending your message, please try again"
              : "Hubo un problema al enviar tu mensaje, intentalo nuevamente")}
        </h2>
        <div
          style={{
            display: "flex",
            width: "70%",
            alignSelf: "center",
            justifyContent: "center",
          }}
        >
          <button className={styles.submitButton} onClick={() => close()}>
            {language ? "Close" : "Cerrar"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CustomModal;
