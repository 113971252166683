import styles from "./navBar.module.scss";
import { Router } from "react-router-dom";
import { Link } from "react-router-dom";
const LinkItem = ({ label, to, onClick }) => {
  return (
    <Link onClick={onClick} className={styles.link} to={to}>
      <span>{label}</span>
    </Link>
  );
};

export default LinkItem;
