import pepe from "../assets/images/moviesSeries/laValija.jpg";
import { useState, useEffect } from "react";

const MoviesSeriesCard = ({ props }) => {
  const { title, link, img, order, sub, body } = props;
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const breakpoint = 800;
  return width > breakpoint ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginBottom: 200,
        borderTopWidth: 1,
        borderTopColor: "#ebb419",
        borderTopStyle: "groove",
        borderStyle: "groove",
        borderColor: "#ebb419",
        padding: "2em",
      }}
    >
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        {" "}
        {title}
      </h1>
      <span style={{ textAlign: "center", marginBottom: 16 }}>
        {" "}
        {sub && sub}
      </span>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginTop: "3em",
        }}
      >
        <div style={{ width: "50%", paddingRight: "16px", textAlign: "left" }}>
          {" "}
          {body}
        </div>
        <div
          style={{
            width: "50%",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            marginBottom: 32,
          }}
        >
          {" "}
          <img src={img} width={"100%"} height={"auto"} alt="img" />{" "}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            minHeight: "50vh",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingTop: 16,
          }}
        >
          <iframe
            src={link}
            style={{
              width: "100%",
              height: "100%",
            }}
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginBottom: 100,
        borderBottomStyle: "groove",
        borderBottomStyle: "groove",
        borderBottomColor: "#ebb419",
        padding: "2em",
      }}
    >
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        {title}
      </h1>
      <span style={{ textAlign: "center", marginBottom: 16 }}>
        {" "}
        {sub && sub}
      </span>{" "}
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          display: "flex",
          marginBottom: 32,
        }}
      >
        {" "}
        <img src={img} width={"100%"} height={"auto"} alt="img" />{" "}
      </div>
      <div style={{ width: "100%", textAlign: "justify" }}>{body}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: 32,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            minHeight: "300px",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: 32,
            marginBottom: 120,
          }}
        >
          <iframe
            src={link}
            style={{
              width: "100%",
              height: "100%",
            }}
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default MoviesSeriesCard;
