import { useState, useEffect } from "react";
import styles from "./navBar.module.scss";
import LinkItem from "./link";
import Logo from "../logo";
import useLanguage from "../../../hooks/useLanguage";
import News from "../../../routes/news";
import SideMenu from "../../sideMenu/sideMenu";

const NavBar = ({ setScrollTo }) => {
  const { language, saveLanguage } = useLanguage();
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const breakpoint = 800;

  return (
    <div className={styles.container}>
      <Logo />
      {width > breakpoint ? (
        <div className={styles.linksContainer}>
          <LinkItem
            label="ABOUT"
            to={"/"}
            onClick={() => setScrollTo("About")}
          />
          <LinkItem
            label={language ? "CONTACT" : "CONTACTO"}
            to={"/"}
            onClick={() => setScrollTo("Contact")}
          />
          <LinkItem label="DIRECTOR" to={"/director"} />
          <LinkItem
            label={language ? "MOVIES & SERIES" : "PELICULAS Y SERIES"}
            to={"/movies&series"}
          />

          {/* <LinkItem label="Video Clips" to={"/videoclips"} />
           <LinkItem label="Shows" to={"/shows"} />*/}
          {/*  <LinkItem label="EDITOR" to={"/editor"} />*/}
          <LinkItem label={language ? "NEWS" : "NOTICIAS"} to={"/news"} />
          <b>
            <LinkItem
              label={!language ? "ENGLISH" : "ESPAÑOL"}
              onClick={() => saveLanguage(!language)}
            />
          </b>
        </div>
      ) : (
        <div className={styles.sideMenuWrapper}>
          <SideMenu setScrollTo={setScrollTo} />
        </div>
      )}
    </div>
  );
};

export default NavBar;
