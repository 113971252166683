import { useState, useEffect } from "react";
import logoText from "../../assets/images/logoText.jpg";
import { useNavigate } from "react-router-dom";
import styles from "./navBar/navBar.module.scss";

const Logo = () => {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const breakpoint = 800;

  return (
    <img
      className={width > breakpoint ? styles.img : styles.imgMobile}
      onClick={() => navigate("/")}
      src={logoText}
      alt="logo"
    />
  );
};

export default Logo;
