import mwLogo from "../../assets/images/mwLogo.jpg";

import styles from "./footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.container}>
      <span>Mariano Dawidson</span>
      <img className={styles.mwLogo} src={mwLogo} alt="mwLogo logo" />
    </div>
  );
};

export default Footer;
