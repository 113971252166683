import React, { useState } from "react";
import "./sideMenu.css";
import { slide as Menu } from "react-burger-menu";
import LinkItem from "../navBar/navBar/link";
import useLanguage from "../../hooks/useLanguage";

export default function SideMenu({ setScrollTo }) {
  const [open, setOpen] = useState({ menuOpen: false });
  const [show, setShow] = useState(true);

  const { language, saveLanguage } = useLanguage();
  //https://codesandbox.io/s/react-dtyh6?file=/src/NavBar.js:598-851

  /*  border-color: rgb(255, 217, 0);
  border-width: 1px;
  border-style: groove;*/

  const handleScroll = (where) => {
    setScrollTo(where);

    setTimeout(() => {
      setOpen({ menuOpen: false });
    }, 300);
  };

  return (
    <div className="burgerVisible">
      <Menu isOpen={open} onStateChange={() => setShow(!show)}>
        <div className="side-menu-container">
          <div className="menu-items">
            <LinkItem
              label="ABOUT"
              to={"/"}
              onClick={() => {
                handleScroll("About");
              }}
            />
          </div>
          <div className="menu-items">
            <LinkItem
              label={language ? "CONTACT" : "CONTACTO"}
              to={"/"}
              onClick={() => {
                handleScroll("Contact");
              }}
            />
          </div>
          <div className="menu-items">
            <LinkItem
              label="DIRECTOR"
              to={"/director"}
              onClick={() => {
                setOpen({ menuOpen: false });
              }}
            />
          </div>{" "}
          <div className="menu-items">
            <LinkItem
              label={language ? "MOVIES & SERIES" : "PELICULAS Y SERIES"}
              to={"/movies&series"}
            />{" "}
          </div>
          {/*   
          <div className="menu-items">
            <LinkItem
              label="Video Clips"
              to={"/videoclips"}
              onClick={() => {
                setOpen({ menuOpen: false });
              }}
            />
          </div>
          <div className="menu-items">
            <LinkItem
              label="Shows"
              to={"/shows"}
              onClick={() => {
                setOpen({ menuOpen: false });
              }}
            />
            </div> 
          <div className="menu-items">
            <LinkItem
              label="EDITOR"
              to={"/editor"}
              onClick={() => {
                setOpen({ menuOpen: false });
              }}
            />
          </div>*/}
          <div className="menu-items">
            <LinkItem
              label={!language ? "NOTICIAS" : "NEWS"}
              to={"/news"}
              onClick={() => {
                setOpen({ menuOpen: false });
              }}
            />
          </div>
          <div className="menu-items">
            {" "}
            <b>
              <LinkItem
                label={!language ? "ENGLISH" : "ESPAÑOL"}
                onClick={() => saveLanguage(!language)}
              />
            </b>
          </div>
        </div>
      </Menu>
    </div>
  );
}
