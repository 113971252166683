import { useState, useRef } from "react";
import styles from "./form.module.scss";
import ContactLinks from "../ContactLinks/contactLinks";
import useLanguage from "../../hooks/useLanguage";
import emailjs from "@emailjs/browser";
import CustomModal from "../modal";

const ContactForm = () => {
  const { language } = useLanguage();
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const isValid = validateAll();
    console.log("errror");
    if (isValid) {
      console.log("errror2");
      emailjs
        .sendForm(
          "service_s62exvf", // id account
          "template_44karc7", // id template
          form.current,
          "AQrXqfvBtg5MA4TIy" //acount / public
        )
        .then(
          (result) => {
            console.log("errror3", result);
            setModalOpen(true);
          },
          (error) => {
            console.log("errror", error);
            setError(false);
            setModalOpen(true);
          }
        );
    }
  };

  const [values, setValues] = useState({
    name: "",
    email: "",
    text: "",
  });

  const [validations, setValidations] = useState({
    name: "",
    email: "",
    text: "",
  });

  const validateAll = () => {
    const { name, email, text } = values;
    const validations = { name: "", email: "", text: "" };
    let isValid = true;

    if (!name) {
      validations.name = "Name is required";
      isValid = false;
    }

    if ((name && name.length < 3) || name.length > 50) {
      validations.name = "Name must contain between 3 and 50 characters";
      isValid = false;
    }

    if (!email) {
      validations.email = "Email is required";
      isValid = false;
    }

    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = "Email format must be as example@mail.com";
      isValid = false;
    }

    if (!text) {
      validations.gender = "text is required";
      isValid = false;
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const validateOne = (e) => {
    const { name } = e.target;
    const value = values[name];
    let message = "";

    if (!value) {
      message = `${name} is required`;
    }

    if (value && name === "name" && (value.length < 3 || value.length > 50)) {
      message = "Name must contain between 3 and 50 characters";
    }

    if (value && name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      message = "Email format must be as example@mail.com";
    }

    setValidations({ ...validations, [name]: message });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validateAll();

    if (!isValid) {
      return false;
    }

    alert(JSON.stringify(values));
  };

  const { name, email, text } = values;

  const { name: nameVal, email: emailVal, text: textVal } = validations;

  return (
    <div className={styles.container}>
      <h2>{language ? "CONTACT" : "CONTACTO"}</h2>

      <ContactLinks />
      <div className={styles.formContainer}>
        <form ref={form} onSubmit={sendEmail}>
          <div className={styles.inputWrapper}>
            <label>{language ? "Name" : "Contacto"}</label>
            <input
              className={styles.input}
              type="text"
              name="name"
              value={name}
              onChange={handleChange}
              onBlur={validateOne}
            />
            <div>{nameVal}</div>
          </div>

          <div className={styles.inputWrapper}>
            <label>Email:</label>
            <input
              className={styles.input}
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
              onBlur={validateOne}
            />
            <div>{emailVal}</div>
          </div>

          <div className={styles.textAreaWrapper}>
            <label>{language ? "Message" : "Mensaje"}</label>
            <textarea
              style={{ width: "100%", resize: "none", marginTop: 7 }}
              className={styles.textAreaInput}
              type="textarea"
              name="text"
              value={text}
              onChange={handleChange}
              onBlur={validateOne}
              id="w3review"
              rows="8"
              cols="10"
            >
              {text}
            </textarea>
          </div>

          <button
            className={
              values.name && values.email && values.text
                ? styles.submitButton
                : styles.submitButtonDisabled
            }
            value="Send"
            type="submit"
          >
            {language ? "Submit" : "Enviar"}
          </button>
        </form>
      </div>
      <CustomModal
        modalOpen={modalOpen}
        setError={setError}
        error={error}
        setModalOpen={setModalOpen}
      />
    </div>
  );
};

export default ContactForm;
