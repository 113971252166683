import styles from "./about.module.scss";
import useLanguage from "../../hooks/useLanguage";
import mariano from "../../assets/images/director/Mariano_006.jpg";
import { useState, useEffect } from "react";
const About = () => {
  const { language } = useLanguage();
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const breakpoint = 800;
  return (
    <div className={styles.container}>
      <div className={styles.textWrapper}>
        <h1
          style={{
            fontSize: "3em",
            textAlign: "center",
          }}
        >
          {" "}
          MARIANO DAWIDSON
        </h1>{" "}
        <img
          className={styles.img}
          src={mariano}
          style={width < breakpoint ? { width: "88%" } : null}
        />
        <div
          style={{
            borderStyle: "groove",
            borderColor: "#ebb419",
            padding: "2em",
            borderWidth: 1,
          }}
          className={styles.aboutBox}
        >
          {!language ? (
            <div className={styles.textBox}>
              <p className={styles.text}>
                {" "}
                Bienvenidos a mi sitio web, soy un director de cine y videoclips
                apasionado por contar historias a través de mi cámara.{" "}
              </p>{" "}
              <p className={styles.text}>
                {" "}
                Desde el año 2002, he estado involucrado en la creación de
                producciones audiovisuales, comenzando con mi primer
                cortometraje que fue premiado en festivales nacionales e
                internacionales.
              </p>{" "}
              <p className={styles.text}>
                {" "}
                En el 2005, fundé Hermanos Dawidson Films junto con mi hermano,
                donde hemos brindado servicios de producción y postproducción
                durante los últimos 15 años.{" "}
              </p>
              <p className={styles.text}>
                {" "}
                Hemos trabajado en proyectos que abarcan animación 2D y 3D,
                dictando talleres, produciendo documentales, videoclips y
                películas de ficción.
              </p>
              <p className={styles.text}>
                {" "}
                Tuve el honor de ser galardonado con numerosos premios,
                incluyendo el premio Gardel al mejor videoclip por la canción
                "Hoy bailaré" de Leon Gieco en 2012, y fui nominado para un
                premio Latin Grammy en la categoría "mejor videoclip" en 2019.
              </p>
              <p className={styles.text}>
                {" "}
                Como socio de DAC Directores Argentinos Cinematográficos,
                participó activamente en la lucha por los derechos de autor de
                los directores de videoclips, y en 2019 fundé la Cámara
                Argentina de Videoclip junto con otros 10 colegas.
              </p>
              <p className={styles.text}>
                {" "}
                Mi trabajo ha sido reconocido a nivel internacional, como lo
                demuestra el estreno de mi primer largometraje "La sangre del
                gallo" en el Festival Internacional de Cine de la ciudad de Mar
                del Plata en 2015, y su posterior venta a la cadena Starz para
                el territorio de Estados Unidos y a Netflix para todo el mundo.{" "}
              </p>
              <p className={styles.text}>
                {" "}
                También dirigí junto a mi hermano la película "La Casa Acecha"
                de estreno digital en épocas de pandemia.
              </p>
              <p className={styles.text}>
                {" "}
                Recientemente, he sido convocado por la productora NON STOP para
                dirigir las escenas de acción de la serie "Cielo Grande" para
                Netflix, y he realizado la consultoría de montaje de dos
                películas para la productora Bufalo Films para Netflix.
              </p>
              <p className={styles.text}>
                {" "}
                También dirigí el videoclip que se convirtió en la canción del
                mundial de fútbol llamado "Muchachos" con 30 millones de vistas
                en menos de dos semanas.
              </p>
              <p className={styles.text}>
                {" "}
                En los últimos 20 años, he desarrollado habilidades en
                diferentes áreas del cine, como dirección, edición, dirección de
                fotografía/cámara y colorización.{" "}
              </p>
              <p className={styles.text}>
                {" "}
                He dirigido más de 300 videoclips, sesiones en vivo, shows en
                estadios, cubrí giras, filmé documentales y ficciones.{" "}
              </p>
              <p className={styles.text}>
                {" "}
                Me dedico a contar historias de cualquier forma posible.
              </p>{" "}
            </div>
          ) : (
            <div>
              <p className={styles.text}>
                Welcome to my website, I am a passionate film and music video
                director who loves to tell stories through my camera lens.{" "}
              </p>{" "}
              <p className={styles.text}>
                I’ve been involved in audiovisual production since 2002, when I
                made my first short film, that would go on to win awards at
                national and international festivals{" "}
              </p>{" "}
              <p className={styles.text}>
                {" "}
                In 2005, I founded Hermanos Dawidson Films with my brother,
                where we provided production and post-production services for 15
                years{" "}
              </p>{" "}
              <p className={styles.text}>
                {" "}
                We’ve worked on projects that cover 2D and 3D animation, given
                workshops, produced documentaries, music videos, and feature
                films{" "}
              </p>{" "}
              <p className={styles.text}>
                {" "}
                I’ve had the honor of being awarded numerous prizes, including
                the Gardel award for best music video for Leon Gieco's "Hoy
                bailaré" in 2012, and was nominated for a Latin Grammy award in
                the "best music video" category in 2019.{" "}
              </p>{" "}
              <p className={styles.text}>
                As a member of DAC (Argentine Cinematographic Directors), I’ve
                actively participated in the fight for the rights of music video
                directors, and in 2019 I founded the Cámara Argentina de
                Videoclips (Argentine Chamber of Music Videos) along with 10
                other colleagues.{" "}
              </p>{" "}
              <p className={styles.text}>
                My work has been recognized internationally, as demonstrated by
                the premiere of my first feature film "La sangre del gallo" at
                the Mar del Plata International Film Festival in 2015, and its
                subsequent sale to Starz for the United States territory and to
                Netflix worldwide.{" "}
              </p>{" "}
              <p className={styles.text}>
                I also directed the digital premiere film "La Casa Acecha" with
                my brother during the pandemic.{" "}
              </p>{" "}
              <p className={styles.text}>
                {" "}
                Recently, I was contracted by NON STOP productions to direct the
                action scenes for the series "Cielo Grande" for Netflix, and I
                have also provided editing consultancy for two films for Bufalo
                Films for Netflix.{" "}
              </p>{" "}
              <p className={styles.text}>
                In addition I directed the music video that became the song of
                the Argentina National Team for the FIFA World Cup called
                "Muchachos" with 30 million views in less than two weeks.{" "}
              </p>{" "}
              <p className={styles.text}>
                Over the past 20 years I have developed skills in different
                areas of film, such as directing, editing, cinematography/camera
                direction, and color grading.{" "}
              </p>{" "}
              <p className={styles.text}>
                I have directed more than 300 music videos, live sessions, arena
                shows, covered tours, filmed documentaries, and fiction.{" "}
              </p>{" "}
              <p className={styles.text}>
                {" "}
                I am dedicated to telling stories in any possible way.{" "}
              </p>{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
