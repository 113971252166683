import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import videos from "../videoObjects";
import VideoCard from "../components/videoCard/videoCard";
import styles from "./routes.module.scss";
import { useNavigate } from "react-router-dom";

const Videos = () => {
  const { section } = useParams();
  const [filtered, setFiltered] = useState();
  const [filtered2, setFiltered2] = useState();
  const [filtered3, setFiltered3] = useState();
  const [all, setAll] = useState();
  const navigate = useNavigate();

  //const sectionUpperCase = section.charAt(0).toUpperCase() + section.slice(1);

  useEffect(() => {
    const x = videos.filter((video) => video.cat === "director");

    setAll(
      x.sort((a, b) => {
        return a.order - b.order;
      })
    );
    const filteredVideos = videos.filter((video) => video.cat === "director");

    if (filteredVideos.length > 3) {
      const threePartIndex = Math.ceil(filteredVideos.length / 3);

      const thirdPart = filteredVideos.splice(-threePartIndex);
      const secondPart = filteredVideos.splice(-threePartIndex);
      const firstPart = filteredVideos;
      if (filteredVideos[0]) {
        setFiltered(secondPart);
        setFiltered2(thirdPart);
        setFiltered3(firstPart);
      } else {
        navigate("/error");
      }
    } else {
      setFiltered(x);
      setFiltered2();
      setFiltered3();
    }
  }, []);

  return (
    <>
      {" "}
      <h1
        style={{
          marginBottom: "2em",
          fontSize: "4em",
          textAlign: "center",
        }}
      >
        {" "}
        Director
      </h1>
      <div
        style={{
          display: "flex",
          maxWidth: "100%",
          flexWrap: "wrap",
          justifyContent: "center",
          padding: "2em",
          marginBottom: "4em",
        }}
      >
        {" "}
        {all &&
          all.map((v) => {
            return (
              <div style={{ margin: "0.2em" }}>
                <VideoCard img={v.img} title={v.title} />
              </div>
            );
          })}
      </div>
    </>
  );
};
export default Videos;
