import styles from "./newsCard.module.scss";
const NewsCard = ({ news }) => {
  console.log("news", news);
  return (
    <div className={styles.container}>
      <h3 style={{ textAlign: "center" }}>{news && news.title}</h3>
      <p style={{ wordWrap: "break-word" }}>{news && news.body}</p>
      {news && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            minWidth: 0,
            flexWrap: "wrap",
            flexShrink: "0.1",
          }}
        >
          {news.links.map((link) => {
            return (
              <div style={{ margin: "0.5em" }}>
                <a
                  target="_blank"
                  attribute
                  href={link.link}
                  style={{ textDecoration: "underline", color: "black" }}
                >
                  {" "}
                  {link.title}
                </a>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NewsCard;
