import NewsCard from "../components/newsCard/NewsCard";
import styles from "./routes.module.scss";
import Mar from "../assets/images/Mariano_002.jpg";
import { useState, useEffect } from "react";

const News = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const breakpoint = 650;
  const newsArray = [
    {
      title:
        "Benito Cerati y Richard Coleman filmaron un videoclip 'Fuerza Natural' en Ushuaia",
      body: `Bajo la dirección de Mariano Dawidson, el hijo de Gustavo y su amigo y colega viajaron a la
  ciudad del fin del mundo para rodar la canción que dio título al último disco del ex líder de Soda
  Stereo`,
      links: [
        {
          title: "Tv Pública ",
          link: "https://www.youtube.com/watch?v=UNHsw-KRhZQ",
        },
        {
          title: "Billboard",
          link: `https://billboard.com.ar/benito-cerati-y-richard-coleman-se-unen-en-la-reversion-de-fuerza-n
          atural/`,
        },
        {
          title: "Fm Rock&Pop",
          link: `https://fmrockandpop.com/noticias/musica/escucha-fuerza-natural-por-benito-cerati-y-richard
          -coleman`,
        },
        {
          title: "Rolling Stone",
          link: `https://es.rollingstone.com/arg-richard-coleman-y-benito-cerati-grabaron-una-version-minima
          lista-de-fuerza-natural/`,
        },
      ],
    },
    {
      title: "Visita virtual guiada 360 Teatro San Martin",
      body: ``,
      links: [
        {
          title: "Complejo Teatral",
          link: "https://complejoteatral.gob.ar/paginas/visita-360",
        },
      ],
    },
    {
      title: "Director de escenas de acción para la serie Cielo Grande",
      body: ``,
      links: [
        {
          title: "IMDB",
          link: "https://www.imdb.com/title/tt12379170/?ref_=nv_sr_srsg_0",
        },
      ],
    },
    {
      title: "Consultoría de montaje en el largometraje Matrimillas - Netflix",
      body: ``,
      links: [
        {
          title: "IMDB",
          link: "https://www.imdb.com/title/tt18688184/fullcredits/?ref_=tt_cl_sm",
        },
      ],
    },
    {
      title: "Mini Serie “ECOS” Homenaje por los 60 años del Teatro San Martin",
      body: `Las paredes guardan historias, ahí, al alcance de quien quiera detenerse para escucharlas.
      Por eso no es casual que quien habite este edificio esté acostumbrado a convivir con ellas,
      y con los ecos de sus personajes protagónicos. Un recorrido plástico por las venas del
      teatro General San Martín en su 60 aniversario, en 6 episodios.`,
      links: [
        {
          title: "Ecos",
          link: "http://www.emilianodionisi.com/ecos.html",
        },
        {
          title: "Unlam",
          link: "https://www.youtube.com/watch?v=ixbyXYRC8c4&ab_channel=unlamtv",
        },
        {
          title: "Tv Pública ",
          link: "https://www.youtube.com/watch?v=0pKJPzCSg8Y",
        },
        {
          title: "Zibilia>",
          link: "https://www.zibilia.com/=EcosHomenajePorLos60AnosDelTeatroSanMartin",
        },
        {
          title: "La Nacion",
          link: `https://www.lanacion.com.ar/espectaculos/teatro/teatro-san-martin-los-ecos-de-la-guerra-por
-las-malvinas-empanados-por-un-robo-en-la-sala-nid18032022/`,
        },
        {
          title: "Escenarios Nacionales",
          link: `https://www.escenariosnacionales.ar/modos-hibridos-ecos-homenaje-por-los-60-anos-del-te
          atro-san-martin-de-emiliano-dionisi/`,
        },
      ],
    },
    {
      title: `Alejandro Terán lleva al CCK "La Trampa" que reunió lo orquestal con las nuevas
      escenas - TELAM SE 2022.`,
      body: `En el espectáculo, una formación instrumental electroacústica interactúa con jóvenes voces
      de la escena urbana y pop, que tiene una puesta documental en YouTube y que para su
      mentor "por suerte mutó desde la idea original porque se fue alejando de ser una muestra
      de la escena juvenil"`,
      links: [
        {
          title: `Telam`,
          link: `https://www.telam.com.ar/notas/202109/568817-alejandro-teran-lleva-al-cck-la-trampa-que-r
          eunio-lo-orquestal-con-las-nuevas-escenas.html`,
        },
        {
          title: `La Nacion`,
          link: `https://www.lanacion.com.ar/espectaculos/musica/de-que-se-trata-la-trampa-el-espectaculo-
          que-reune-a-figuras-del-trap-y-el-pop-con-una-orquesta-nid06092021/`,
        },
        {
          title: `Grupo la provincia`,
          link: `https://www.grupolaprovincia.com/espectaculos/alejandro-teran-lleva-al-cck-la-trampa-que-r
          eunio-lo-orquestal-con-las-nuevas-escenas-796252`,
        },
        {
          title: `
          FM Signos Urdampilleta`,
          link: `http://fmsignosurdampilleta.com.ar/alejandro-teran-lleva-al-cck-la-trampa-que-reunio-lo-orqu
          estal-con-las-nuevas-escenas/`,
        },
        {
          title: `
          Filo news`,
          link: `https://www.filo.news/La-Trampa-una-sinfonica-distopica-con-artistas-traperos-y-urbanos--l2
          02109070001.html`,
        },
        {
          title: `Domestika`,
          link: `https://www.domestika.org/es/projects/1300525-lettering-para-titulos-video-recital-la-trampa`,
        },
        {
          title: `Chaco todo el dia`,
          link: `https://chacotodoeldia.com/alejandro-teran-lleva-al-cck-la-trampa-que-reunio-lo-orquestal-co
          n-las-nuevas-escenas/`,
        },
        {
          title: `Noticiero diario`,
          link: `https://noticierodiario.com/cultura/alejandro-teran-lleva-la-trampa-al-cck-que-reunio-a-la-orqu
          estal-con-las-nuevas-escenas`,
        },
      ],
    },
    {
      title: "Thet Studio presenta el spot de la Gastro Japo Food Week",
      body: `La agencia fue la responsable, por tercer año consecutivo, de desarrollar la creatividad de la
      edición 2021 de la semana dedicada a la gastronomía japonesa, de la cual además formó
      parte de la organización integral.`,
      links: [
        {
          title: "Dossier net",
          link: `https://dossiernet.com/articulo/thet-studio-presenta-el-spot-de-la-gastro-japo-food-week/269
          87`,
        },
        {
          title: "Total medios",
          link: `https://www.totalmedios.com/nota/46619/thet-studio-presenta-el-spot-de-la-gastro-japo-food-
          week`,
        },
      ],
    },
    {
      title: "La Casa Acecha de Eric y Mariano Dawidson",
      body: `Un personaje siniestro protege una casa abandonada, llevando a sus habitantes a la locura
      y la muerte.`,
      links: [
        {
          title: "Habitacion 1520",
          link: `http://www.habitacion1520.com/la-casa-acecha-el-guardian/`,
        },
        {
          title: "cba 24n",
          link: `https://www.cba24n.com.ar/espectaculos/terror-y-comedia-en-los-estrenos-de-cine-de-la-se
          mana_a5ee39cf5d632973de215d04e`,
        },
        {
          title: "Puntual",
          link: `https://www.puntal.com.ar/cine/la-cartelera-trae-cuatro-novedades-n106927`,
        },
        {
          title: "Pagina 12",
          link: `https://www.pagina12.com.ar/453884-estreno-de-la-semana-la-casa-acecha`,
        },
        {
          title: "Cines",
          link: `https://cines.com/pelicula/la-casa-acecha-2020`,
        },
        {
          title: "Filmaffinity",
          link: `https://www.filmaffinity.com/ar/film726781.html`,
        },
        {
          title: "Noticine",
          link: `https://noticine.com/noticias/estrenos/30563-mike-amigorena-y-leonora-balcarce-en-la-casa-
          acecha-estreno-de-la-semana-en-cine-ar.html`,
        },
        {
          title: "Cine Argntino Hoy",
          link: `https://cineargentinohoy.com.ar/un-filme-que-revive-mitos-sobrenaturales-y-la-sugestion-des
          controlada-critica-de-la-casa-acecha/`,
        },
        {
          title: "La Capital Mdp",
          link: `https://www.lacapitalmdp.com/estrenan-las-peliculas-la-casa-acecha-y-cumbia-que-te-vas-d
          e-ronda/`,
        },
        {
          title: "Funcinema",
          link: `https://www.funcinema.com.ar/2020/06/la-casa-acecha/`,
        },
        {
          title: "Todas las criticas",
          link: `https://www.todaslascriticas.com.ar/pelicula/la-casa-acecha`,
        },
        {
          title: "Que digital",
          link: `https://quedigital.com.ar/cultura/el-programa-jueves-estreno-lanza-dos-peliculas-nacionales-
          esta-semana/`,
        },
        {
          title: "La vanguardia",
          link: `https://www.lavanguardia.com/peliculas-series/peliculas/la-casa-acecha-713924`,
        },
        {
          title: "Gps audiovisual",
          link: `https://gpsaudiovisual.com/2020/06/10/la-casa-acecha-estreno-en-linea-jueves-11-de-junio/`,
        },
      ],
    },
    {
      title: "Mini Serie “Mañanas Oscuras”",
      body: `Mañanas oscuras propone una reflexión sobre temáticas que la vida cotidiana busca eludir.
      En un mundo donde todos venden sonrisas, alegría y éxito, la verdad solo puede venir
      acompañada de un halo de oscuridad.`,
      links: [
        {
          title: "Un3.tv",
          link: `http://un3.tv/programas/mananas-oscuras/?fbclid=IwAR0YStD5FiP9aCz_w1GdR6KpLu7L51
          ON83-NKdIzMHIvitYLOQMzo757Hl4`,
        },
        {
          title: "Pagina 12",
          link: `https://www.pagina12.com.ar/223458-el-goce-estetico-de-la-mala-onda`,
        },
        {
          title: "Total medios",
          link: `https://www.totalmedios.com/nota/38656/un3-estrena-la-serie-mananas-oscuras`,
        },
        {
          title: "Serie mania",
          link: `https://seriemania.tv/noticias/se-viene/el-estreno-de-mananas-oscuras-2428/`,
        },
        {
          title: "Untref",
          link: `https://www.untref.edu.ar/mundountref/junio-premios-novedades-un3`,
        },
        {
          title: "Qepd",
          link: `https://qepd.news/mananas-oscuras-con-juan-sklar-estrena-el-6-de-junio-en-un3/`,
        },
        {
          title: "Grupo la provincia",
          link: `https://www.grupolaprovincia.com/espectaculos/llega-manana-oscuras-reflexiones-existenci
          ales-en-formato-serie-web-300185`,
        },
        {
          title: "Funcinema",
          link: `https://www.funcinema.com.ar/2019/06/un3-estrenara-manana-oscuras-protagonizada-por-j
          uan-sklar/`,
        },
      ],
    },
    {
      title: "La Sangre del Gallo",
      body: `Netflix estrena la película argentina "La sangre del gallo" El film contará las actuaciones de
      Santiago Pedrero, Emiliano Carrazzone, Coni Marino, Roly Serrano, Susana Varela y la
      participación especial de León Gieco. El thriller psicológico "La Sangre del Gallo", ópera
      prima de los directores argentinos Eric y Mariano Dawidson, será estrenada en la
      plataforma de contenidos audiovisuales Netflix.`,
      links: [
        {
          title: "Resumen de la region",
          link: "https://www.resumendelaregion.com/netflix-estrena-la-pelicula-argentina-la-sangre-del-gallo/",
        },
        {
          title: "La voz",
          link: `https://www.lavoz.com.ar/temas/la-sangre-del-gallo//`,
        },
        {
          title: "Telam",
          link: `https://www.telam.com.ar/notas/201704/184355-netflix-estrena-la-pelicula-argentina-la-sangr
          e-del-gallo.html`,
        },
        {
          title: "Cinemaldito",
          link: `https://www.cinemaldito.com/trailer-del-thriller-psicologico-la-sangre-del-gallo/`,
        },
        {
          title: "La Capital Mdp",
          link: `https://www.lacapitalmdp.com/temas/la-sangre-del-gallo/`,
        },
        {
          title: "Rosario3",
          link: `https://www.rosario3.com/ocio/Netflix-estrena-el-film-argentino-La-sangre-del-Gallo-2017033
          1-0054.html`,
        },

        {
          title: "Escribiendo cine",
          link: `https://www.escribiendocine.com/noticias/2015/11/07/2982-la-sangre-del-gallo`,
        },
      ],
    },
  ];

  return (
    <div className={styles.container}>
      <h1
        style={{
          marginBottom: "2em",
          marginTop: "2em",
          fontSize: "4em",
        }}
      >
        {" "}
        News
      </h1>
      {/*
      <img
        src={Mar}
        style={{
          width: breakpoint > width ? "280px" : "600px",
          minWidth: breakpoint > width ? "280px" : "600px",
          height: "auto",
          marginBottom: "3em",
          marginTop: "3em",
        }}
      /> */}
      {newsArray.map((news) => {
        return <NewsCard news={news} />;
      })}
    </div>
  );
};

export default News;
