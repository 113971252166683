import siteveo from "./assets/images/director/siteveo.jpg";
import sola from "./assets/images/director/sola.jpg";
import solomeimporta from "./assets/images/director/solomeimporta.jpg";
import somoslosotros from "./assets/images/director/somoslosotros.jpg";
import sonambulo from "./assets/images/director/sonambulolevare.jpg";
import speedracer from "./assets/images/director/speedracer.jpg";
import teestasenamorando from "./assets/images/director/teestasenamorando.jpg";
import thewayback from "./assets/images/director/thewayback.jpg";
import traful from "./assets/images/director/traful.jpg";
import tramposa from "./assets/images/director/tramposa.jpg";
import tuemblema from "./assets/images/director/tuemblema.jpg";
import vivirloscolores from "./assets/images/director/vivirloscolores.jpg";
import volverteaver from "./assets/images/director/volverteaver.jpg";
import wild from "./assets/images/director/wild.jpg";
import ydeshacer from "./assets/images/director/ydeshacer.jpg";
import vlone from "./assets/images/director/vlone.jpg";
import amame from "./assets/images/director/amame.jpg";
import amor from "./assets/images/director/amor.jpg";
import aprenderas from "./assets/images/director/aprenderas.jpg";
import asesina from "./assets/images/director/asesina.jpg";
import axelfiks from "./assets/images/director/axelfiks.jpg";
import babybaby from "./assets/images/director/babybaby.jpg";
import bailemosunblues from "./assets/images/director/bailemosunblues.jpg";
import boicot from "./assets/images/director/bailemosunblues.jpg";
import cabaret from "./assets/images/director/cabaret.jpg";
import calendario from "./assets/images/director/calendario.jpg";
import caramel from "./assets/images/director/caramel.jpg";
import cenit from "./assets/images/director/cenit.jpg";
import claracava from "./assets/images/director/claracava.jpg";
import clubdelaselva from "./assets/images/director/clubdelaselva.jpg";
import contratodoslosmales from "./assets/images/director/contratodoslosmales.jpg";
import corazonpaola from "./assets/images/director/corazonpaola.jpg";
import corazonvaliente from "./assets/images/director/corazonvaliente.jpg";
import cosasdulces from "./assets/images/director/cosasdulces.jpg";
import culo from "./assets/images/director/culo.jpg";
import darkfire from "./assets/images/director/darkfire.jpg";
import deanimal from "./assets/images/director/deanimal.jpg";
import dejarteir from "./assets/images/director/dejarteir.jpg";
import dopamina from "./assets/images/director/dopamina.jpg";
import ecos1 from "./assets/images/director/ecos1.jpg";
import ecos2 from "./assets/images/director/ecos2.jpg";
import ecos3 from "./assets/images/director/ecos3.jpg";
import ecos4 from "./assets/images/director/ecos4.jpg";
import ecos5 from "./assets/images/director/ecos5.jpg";
import ecos6 from "./assets/images/director/ecos6.jpg";
import eldestino from "./assets/images/director/eldestino.jpg";
import electrapino from "./assets/images/director/electrapino.jpg";
import elembrujo from "./assets/images/director/elembrujo.jpg";
import ellatienealgo from "./assets/images/director/ellatienealgo.jpg";
import eltemporal from "./assets/images/director/eltemporal.jpg";
import encontrarte from "./assets/images/director/encontrarte.jpg";
import enelseno from "./assets/images/director/enelseno.jpg";
import enelsenodelamor from "./assets/images/director/enelsenodelamor.jpg";
import enseniameavivir from "./assets/images/director/enseniameavivir.jpg";
import estamoslistos from "./assets/images/director/estamoslistos.jpg";
import fango from "./assets/images/director/fango.jpg";
import fuerzanatural from "./assets/images/director/fuerzanatural.jpg";
import fuiste from "./assets/images/director/fuiste.jpg";
import gabymoreno from "./assets/images/director/gabymoreno.jpg";
import gastrojapo from "./assets/images/director/gastrojapo.jpg";
import gipsynight from "./assets/images/director/gipsynight.jpg";
import guardare from "./assets/images/director/guardare.jpg";
import halloween from "./assets/images/director/halloween.jpg";
import hojaenblanco from "./assets/images/director/hojaenblanco.jpg";
import holaildivo from "./assets/images/director/holaildivo.jpg";
import hombremujer from "./assets/images/director/hombremujer.jpg";
import invisible from "./assets/images/director/invisible.jpg";
import jets from "./assets/images/director/jets.jpg";
import kastiello from "./assets/images/director/kastiello.jpg";
import laberintos from "./assets/images/director/laberintos.jpg";
import lacasa from "./assets/images/director/lacasa.jpg";
import laeradeacuario from "./assets/images/director/laeradeacuario.jpg";
import lairadedios from "./assets/images/director/lairadedios.jpg";
import lasluces from "./assets/images/director/lasluces.jpg";
import latrampa from "./assets/images/director/latrampa.jpg";
import lesdos from "./assets/images/director/lesdos.jpg";
import lgante from "./assets/images/director/lgante.jpg";
import locos from "./assets/images/director/locos.jpg";
import loop from "./assets/images/director/loop.jpg";
import losdeafuera from "./assets/images/director/losdeafuera.jpg";
import lospalmerasvaleria from "./assets/images/director/lospalmerasvaleria.jpg";
import macumbia from "./assets/images/director/macumbia.jpg";
import mana from "./assets/images/director/mana.jpg";
import manatour from "./assets/images/director/manatour.jpg";
import mashumanizado from "./assets/images/director/mashumanizado.jpg";
import moskonys from "./assets/images/director/moskonys.jpg";
import movimiento from "./assets/images/director/movimiento.jpg";
import muchachos from "./assets/images/director/muchachos.jpg";
import muerdesulabio from "./assets/images/director/muerdesulabio.jpg";
import mueve from "./assets/images/director/mueve.jpg";
import nochesvacias from "./assets/images/director/nochesvacias.jpg";
import notengo from "./assets/images/director/notengo.jpg";
import notepuedoolvidar from "./assets/images/director/notepuedoolvidar.jpg";
import nothing from "./assets/images/director/nothing.jpg";
import oddmami from "./assets/images/director/oddmami.jpg";
import olvidala from "./assets/images/director/olvidala.jpg";
import ositos from "./assets/images/director/ositos.jpg";
import palmerassoledad from "./assets/images/director/palmerassoledad.jpg";
import paquetenga from "./assets/images/director/paquetenga.jpg";
import pendejomolotov from "./assets/images/director/pendejomolotov.jpg";
import platinomueve from "./assets/images/director/platinomueve.jpg";
import prideday from "./assets/images/director/prideday.jpg";
import quequiere from "./assets/images/director/quequiere.jpg";
import rastromarilina from "./assets/images/director/rastromarilina.jpg";
import rebelion from "./assets/images/director/rebelion.jpg";
import rockstar from "./assets/images/director/rockstar.jpg";
import salevolando from "./assets/images/director/salevolando.jpg";
import santababy from "./assets/images/director/santababy.jpg";
import seniales from "./assets/images/director/seniales.jpg";
import sigoenlamia from "./assets/images/director/sigoenlamia.jpg";
import silohacemos from "./assets/images/director/silohacemos.jpg";
import semeaperdido from "./assets/images/director/semeaperdido.jpg";
import acidocarajo from "./assets/images/director/acidocarajo.jfif";
import amorquesobra from "./assets/images/director/amorquesobra.jfif";
import antesquevuelvaeruca from "./assets/images/director/antesquevuelvaeruca.JPG";
import bendor from "./assets/images/director/bendor.jfif";
import biancawifourth from "./assets/images/director/biancawifourth.jfif";
import bipolaridadparanormal from "./assets/images/director/bipolaridadparanormal.jfif";
import breadof from "./assets/images/director/breadof.jfif";
import buenosaires from "./assets/images/director/buenosaires.jfif";
import ceroyuno from "./assets/images/director/ceroyuno.jfif";
import chicogranadacarajo from "./assets/images/director/chicogranadacarajo.jfif";
import comoseextrana from "./assets/images/director/comoseextrana.jfif";
import creisinverdmente from "./assets/images/director/creisinverdmente.jfif";
import dulcecompania from "./assets/images/director/dulcecompania.jfif";
import eclipseofmoon from "./assets/images/director/eclipseofmoon.jfif";
import ellaleon from "./assets/images/director/ellaleon.jfif";
import ellasnicozuviria from "./assets/images/director/ellasnicozuviria.jfif";
import enunrinconfacu from "./assets/images/director/enunrinconfacu.jfif";
import estanacionhoy from "./assets/images/director/estanacionhoy.jfif";
import exlover from "./assets/images/director/exlover.jfif";
import fabula from "./assets/images/director/fabula.jfif";
import fueraomasalla from "./assets/images/director/fueraomasalla.jfif";
import hairbibiwu from "./assets/images/director/hairbibiwu.jfif";
import hiswoman from "./assets/images/director/hiswoman.jfif";
import hoybailareleon from "./assets/images/director/hoybailareleon.jfif";
import hoymerindoatiemir from "./assets/images/director/hoymerindoatiemir.jfif";
import ingenuadulce from "./assets/images/director/ingenuadulce.jfif";
import laproximavida from "./assets/images/director/laproximavida.jfif";
import lasangredelgallotrailer from "./assets/images/director/lasangredelgallotrailer.jfif";
import lasmujeresdetuvida from "./assets/images/director/lasmujeresdetuvida.jfif";
import lasoledaddmente from "./assets/images/director/lasoledaddmente.jfif";
import lautrec from "./assets/images/director/lautrec.jfif";
import leavingbibi from "./assets/images/director/leavingbibi.jfif";
import losientotanto from "./assets/images/director/losientotanto.jfif";
import lovetonoend from "./assets/images/director/lovetonoend.jfif";
import mentirosa from "./assets/images/director/mentirosa.jfif";
import midevolucion from "./assets/images/director/midevolucion.jfif";
import obichoeo from "./assets/images/director/obichoeo.jfif";
import ohyeabibi from "./assets/images/director/ohyeabibi.jfif";
import onlycare from "./assets/images/director/onlycare.jfif";
import oyevicoc from "./assets/images/director/oyevicoc.jfif";
import pensarbien from "./assets/images/director/pensarbien.jfif";
import pensarennadaleondemente from "./assets/images/director/pensarennadaleondemente.jfif";
import poresoadonde from "./assets/images/director/poresoadonde.jfif";
import quehagoyo from "./assets/images/director/quehagoyo.jpg";
import sanasesino from "./assets/images/director/sanasesino.jfif";
import seraseljuez from "./assets/images/director/seraseljuez.jfif";
import sesionesvivasperdido from "./assets/images/director/sesionesvivasperdido.jfif";
import otravezsusana from "./assets/images/director/otravezsusana.jpeg";
import soloturojo from "./assets/images/director/soloturojo.jfif";
import boicotramen from "./assets/images/director/boicotramen.jpeg";

//import sonambulo from "./assets/images/director/sonambulo.jfif";

import tevibrillar from "./assets/images/director/tevibrillar.jfif";

import tevoyaamaraxel from "./assets/images/director/tevoyaamaraxel.jfif";

import tierradeconejos from "./assets/images/director/tierradeconejos.jfif";
import todopoderoso from "./assets/images/director/todopoderoso.jfif";
import unacancionno from "./assets/images/director/unacancionno.jfif";
import unayotravez from "./assets/images/director/unayotravez.jfif";
import unomass from "./assets/images/director/unomass.jfif";
import verdaderaintensidad from "./assets/images/director/verdaderaintensidad.jfif";
import yoteprotejo from "./assets/images/director/yoteprotejo.jfif";
import zonadepromesas from "./assets/images/director/zonadepromesas.jfif";
//import diaspino from "./assets/images/director/10diaspino.jfif";

const videos = [
  {
    title: "Se Me Ha Perdido Un Corazón - Soledad",
    video: "https://www.youtube.com/embed/ZX4hcvk66nw",
    img: semeaperdido,
    cat: "director",
    order: 84,
  },

  {
    title: "Electra - PINO (ft. LEEVA)",
    video: "https://player.vimeo.com/video/23287239",
    img: electrapino,
    cat: "director",
    order: 82,
  },

  {
    title: "Amor que sobra - Rescate",
    video: "https://player.vimeo.com/video/23287361",
    img: amorquesobra,
    cat: "director",
    order: 83,
  },

  {
    title: "Chico Granada - Carajo",
    video: "https://www.youtube.com/embed/7KCjg9B82QM",
    img: chicogranadacarajo,
    cat: "director",
    order: 17,
  },

  {
    title: "Hoy me rindo a ti - Emir Sensini",
    video: "https://www.youtube.com/embed/iToxxYyo9iE",
    img: hoymerindoatiemir,
    cat: "director",
    order: 161,
  },

  {
    title: "Creí sin ver - D-Mente",
    video: "https://www.youtube.com/embed/p1sk1GHSgww",
    img: creisinverdmente,
    cat: "director",
    order: 85,
  },

  {
    title: "Las Mujeres de tu Vida - La mosca",
    video: "https://www.youtube.com/embed/7qwS4JvLgiw",
    img: lasmujeresdetuvida,
    cat: "director",
    order: 18,
  },

  {
    title: "En un Rincón - Facu Monty",
    video: "https://www.youtube.com/embed/tj-8bC5MZwQ",
    img: enunrinconfacu,
    cat: "director",
    order: 160,
  },

  {
    title: "Hair - Bibi Wu",
    video: "https://player.vimeo.com/video/23506139",

    img: hairbibiwu,
    cat: "director",
    order: 159,
  },

  {
    title: "His Woman - Bibi Wu",
    video: "https://www.youtube.com/embed/yczQN3c15Dc",
    img: hiswoman,
    cat: "director",
    order: 158,
  },

  {
    title: "Leaving - Bibi Wu",
    video: "https://www.youtube.com/embed/yczQN3c15Dc",
    img: leavingbibi,
    cat: "director",
    order: 157,
  },

  {
    title: "Oh Yea! - Bibi Wu",
    video: "https://player.vimeo.com/video/23515371",
    img: ohyeabibi,
    cat: "director",
    order: 156,
  },

  {
    title: "Tierra de Conejos - Luzparis",
    video: "https://www.youtube.com/embed/CwORGgmRgVU",
    img: tierradeconejos,
    cat: "director",

    order: 155,
  },

  {
    title: "Por eso a donde vas, yo voy - La Mosca",
    video: "https://www.youtube.com/embed/yEYSJUfiTaI",
    img: poresoadonde,
    cat: "director",
    order: 86,
  },

  {
    title: "Uno Más - Symbelmyne",
    video: "https://www.youtube.com/embed/6CNeavqs7hE",
    img: unomass,
    cat: "director",
    order: 87,
  },

  {
    title: "Una Canción no Bastará - Ciudad Lypton",
    video: "https://www.youtube.com/embed/t7f669If8AA",
    img: unacancionno,
    cat: "director",
    order: 19,
  },

  {
    title: "Te vi Brillar - D-Mente",
    video: "https://www.youtube.com/embed/7HLWbSHhAsU",
    img: tevibrillar,
    cat: "director",
    order: 88,
  },

  {
    title: "O Bicho e o Diamante - Zebra Zebra",
    video: "https://www.youtube.com/embed/QLRBdyj2EcI",
    img: obichoeo,
    cat: "director",
    order: 154,
  },

  {
    title: "Lo Siento Tanto - Makana",
    video: "https://www.youtube.com/embed/PjYBIul5NXs",
    img: losientotanto,
    cat: "director",
    order: 153,
  },

  {
    title: "Ellas - Nico Zuviría",
    video:
      "https://www.youtube.com/embed/-FD69K6mvGo&list=RD-FD69K6mvGo&start_radio=1",
    img: ellasnicozuviria,
    cat: "director",
    order: 152,
  },

  {
    title: "Solo Tu - Rojo",
    video: "https://www.youtube.com/embed/iXEaTDy2xpY",
    img: soloturojo,
    cat: "director",
    order: 151,
  },

  {
    title: "Dulce Compañía - Omar Herrera",
    video: "https://www.youtube.com/embed/8k-jRiAIWLA",
    img: dulcecompania,
    cat: "director",
    order: 150,
  },

  {
    title: "Te voy a Amar - Axel",
    video: "https://www.youtube.com/embed/KZh60U1PqSE",
    img: tevoyaamaraxel,
    cat: "director",
    order: 20,
  },

  {
    title: "Ingenua - Dulce María",
    video: "https://www.youtube.com/embed/e4CiGyJ-NkY",
    img: ingenuadulce,
    cat: "director",
    order: 145,
  },

  {
    title: "Hoy Bailaré - León Gieco",
    video: "https://www.youtube.com/embed/GU29G6mzWl8",
    img: hoybailareleon,
    cat: "director",
    order: 21,
  },

  {
    title: "Todopoderoso - Nessia",

    video: "https://www.youtube.com/embed/9XMFKtQji14",
    img: todopoderoso,
    cat: "director",
    order: 144,
  },

  {
    title: `Fábula - Comercial para el Shopping Nuevocentro (Córdoba)`,
    video: "https://player.vimeo.com/video/35354272",
    img: fabula,
    cat: "director",
    order: 143,
  },

  {
    title: "Oye - Vico C",
    video: "https://player.vimeo.com/video/37733425",
    img: oyevicoc,
    cat: "director",
    order: 89,
  },

  {
    title: "La Próxima Vida - La Mosca",
    video: "https://player.vimeo.com/video/42241402",
    img: laproximavida,
    cat: "director",
    order: 22,
  },

  {
    title: "Serás el Juez - D-Mente",
    video: "https://www.youtube.com/embed/rsuJAjeLQaI",
    img: seraseljuez,

    cat: "director",
    order: 90,
  },

  {
    title: "Como se extraña - Andre",
    video: "https://www.youtube.com/embed/fJXCxoZehic",
    img: comoseextrana,
    cat: "director",
    order: 142,
  },

  {
    title: "Ella - León Gieco",
    video: "https://www.youtube.com/embed/ARo3c89AcYo",
    img: ellaleon,
    cat: "director",
    order: 23,
  },

  {
    title: "Fuera o Más Allá - Eruca Sativa",
    video: "https://www.youtube.com/embed/V4kjNe1ToOw",
    img: fueraomasalla,
    cat: "director",
    order: 24,
  },

  {
    title: "Encounter with Ex-Lover - Bibi Wu",
    video: "https://player.vimeo.com/video/64732719",
    img: exlover,
    cat: "director",
    order: 141,
  },

  {
    title: "Esta Nación Hoy Vuelve a Ti - Emir Sensini",
    video: "https://www.youtube.com/embed/5EsLasGDLMQ",
    img: estanacionhoy,
    cat: "director",
    order: 140,
  },

  {
    title: "Only care about you - Bibi Wu",
    video: "https://player.vimeo.com/video/66407402",
    img: onlycare,
    cat: "director",
    order: 139,
  },

  {
    title: "Pensar Bien - Connor Questa",
    video: "https://www.youtube.com/embed/4UwRdBqU8i8",
    img: pensarbien,
    cat: "director",
    order: 138,
  },

  {
    title: "Zona de Promesas - Bote",
    video: "https://www.youtube.com/embed/TbbGlYH89O0",
    img: zonadepromesas,
    cat: "director",
    order: 91,
  },

  {
    title: "Bend or Brake - Bibi Wu",
    video: "https://player.vimeo.com/video/84800223",
    img: bendor,
    cat: "director",
    order: 137,
  },

  {
    title: "Love to no End - Bibi Wu",
    video: "https://player.vimeo.com/video/84800225",
    img: lovetonoend,
    cat: "director",
    order: 136,
  },

  {
    title: "Fourth Night Master - Bibi Wu",
    video: "https://player.vimeo.com/video/84800230",
    img: biancawifourth,
    cat: "director",
    order: 135,
  },

  {
    title: "Eclipse of moon - Bibi Wu",
    video: "https://player.vimeo.com/video/84800231",
    img: eclipseofmoon,
    cat: "director",
    order: 190,
  },

  {
    title: "Bread of Cain - Chrisallys",

    video: "https://player.vimeo.com/video/84890785",
    img: breadof,
    cat: "director",
    order: 134,
  },

  {
    title: "Pensar en Nada - León Gieco + D-Mente",
    video: "https://www.youtube.com/embed/zopMu85j5hU",
    img: pensarennadaleondemente,
    cat: "director",
    order: 25,
  },

  {
    title: "La Soledad - D-Mente",
    video: "https://www.youtube.com/embed/pzwG0ACqKZM",
    img: lasoledaddmente,
    cat: "director",
    order: 92,
  },

  {
    title: "Mentirosa - Roberto Ruben",
    video: "https://www.youtube.com/embed/0nGv72XKDQA",
    img: mentirosa,
    cat: "director",
    order: 93,
  },

  {
    title: "Una y Otra Vez - Cabrio",
    video: "https://www.youtube.com/embed/LEMBvqQEv_Y",
    img: unayotravez,

    cat: "director",
    order: 200,
  },

  {
    title: "Otra Vez - Susana",
    video: "https://www.youtube.com/embed/B3bU_VaGYro",
    img: otravezsusana,
    cat: "director",
    order: 133,
  },

  {
    title: " Perdido en Tí - Sesiones Vivas!",
    video: "https://www.youtube.com/embed/iigc7WLNr-Y",
    img: sesionesvivasperdido,
    cat: "director",
    order: 94,
  },

  {
    title: "Cero y Uno - Sesiones Vivas!",
    video: "https://www.youtube.com/embed/ys5moUld8w8",
    img: ceroyuno,
    cat: "director",
    order: 95,
  },

  {
    title: "Antes que Vuelva a Caer - Eruca Sativa",
    video: "https://www.youtube.com/embed/yBP8aHqt508",
    img: antesquevuelvaeruca,
    cat: "director",
    order: 26,
  },

  {
    title: "San Asesino - De La Tierra",
    video: "https://www.youtube.com/embed/nxSkbDF8QSM",
    img: sanasesino,
    cat: "director",
    order: 27,
  },

  {
    title: "Bipolaridad Paranormal - Ultragalana",
    video: "https://www.youtube.com/embed/462MU_A5URI",
    img: bipolaridadparanormal,
    cat: "director",
    order: 132,
  },

  {
    title: "Qué Hago Yo - Dread Mar I",
    video: "https://www.youtube.com/embed/8PRMMUssvto",
    img: quehagoyo,
    cat: "director",
    order: 28,
  },

  {
    title: "Buenos Aires - Todo Aparenta Normal",
    video: "https://www.youtube.com/embed/jIOPmKYVVJk",
    img: buenosaires,
    cat: "director",
    order: 29,
  },

  {
    title: "Mi devolución - Bigger",
    video: "https://www.youtube.com/embed/oeWHkHC43hA",
    img: midevolucion,
    cat: "director",
    order: 30,
  },

  {
    title: "Verdadera Intensidad - Infierno 18",
    video: "https://www.youtube.com/embed/Of0aee3U9rM",
    img: verdaderaintensidad,
    cat: "director",
    order: 31,
  },

  {
    title: "Sonámbulo - Levare",
    video: "https://www.youtube.com/embed/Yosjn8xTBxM",
    img: sonambulo,
    cat: "director",
    order: 32,
  },

  {
    title: "El destino - Francisca y los exploradores (ft. Adrián Dargelos)",

    video: "https://www.youtube.com/embed/GuXZ3b-EBbo",
    img: eldestino,
    cat: "director",
    order: 33,
  },

  {
    title: "Tour 2016 HD - Mana",
    video: "https://player.vimeo.com/video/160917355",
    img: manatour,
    cat: "director",
    order: 34,
  },

  {
    title: "Cosas Dulces - Marilina Bertoldi",
    video: "https://www.youtube.com/embed/BEs-RLL6fm0",
    img: cosasdulces,
    cat: "director",
    order: 35,
  },

  {
    title: "Laberintos - Dread Mar I",
    video: "https://www.youtube.com/embed/vP21-AXb54Y",
    img: laberintos,
    cat: "director",
    order: 36,
  },

  {
    title: "Ella tiene algo - Smile",
    video: "https://www.youtube.com/embed/UPqffrAqukQ",
    img: ellatienealgo,

    cat: "director",
    order: 131,
  },

  {
    title: "Calendario - Todo Aparenta Normal",
    video: "https://www.youtube.com/embed/ssmBBlD0zZw",
    img: calendario,
    cat: "director",
    order: 37,
  },

  {
    title: "Enséñame a vivir - Luciano Pereyra",
    video: "https://www.youtube.com/embed/COwSiHhAsoI",
    img: enseniameavivir,
    cat: "director",
    order: 38,
  },

  {
    title: "Caramel - Gerardo Farez y La Marea Oceánica",
    video: "https://www.youtube.com/embed/S1w3yThr9uU",
    img: caramel,
    cat: "director",
    order: 39,
  },

  {
    title: "Mueve - Abraham Mateo ft Lali",
    video: "https://www.youtube.com/embed/CI2wfoIKrlM",
    img: mueve,
    cat: "director",
    order: 40,
  },

  {
    title: "Señales - De La Tierra",
    video: "https://www.youtube.com/embed/_Tk55fQBo4E",
    img: seniales,
    cat: "director",
    order: 41,
  },

  {
    title: "Hoja en Blanco - Dread Mar I",
    video: "https://www.youtube.com/embed/4dOT1BoJFko",
    img: hojaenblanco,
    cat: "director",
    order: 42,
  },

  {
    title: "Movimiento - Waldemar Reyes",
    video: "https://www.youtube.com/embed/EKxJEcT869I",
    img: movimiento,
    cat: "director",
    order: 43,
  },

  {
    title: "The Way Back Home to You - Mike Rubino",
    video: "https://www.youtube.com/embed/UJ20ApIjAs8",
    img: thewayback,
    cat: "director",
    order: 130,
  },

  {
    title: "Jets Stream - Marie Digby",
    video: "https://www.youtube.com/embed/AEZ6TnaPUg0",
    img: jets,
    cat: "director",
    order: 44,
  },

  {
    title: "Santa Baby - Ana Free",
    video: "https://www.youtube.com/embed/PmD4zZjTklI",
    img: santababy,
    cat: "director",
    order: 129,
  },

  {
    title: "Rastro - Marilina Bertoldi",
    video: "https://www.youtube.com/embed/1-2LQcJoozk",
    img: rastromarilina,
    cat: "director",
    order: 45,
  },

  {
    title: "Rockstar - 202",
    video: "https://www.youtube.com/embed/w4Cy6tH0bqY",
    img: rockstar,
    cat: "director",
    order: 46,
  },

  {
    title: "Contra todos los males de este mundo - Bote (cover Spinetta Jade)",

    video: "https://www.youtube.com/embed/aYq9us_UU_s",
    img: contratodoslosmales,
    cat: "director",
    order: 97,
  },

  {
    title: "Dark Fire - Mike Rubino",
    video: "https://www.youtube.com/embed/OwpplGCX7RU",
    img: darkfire,
    cat: "director",
    order: 128,
  },

  {
    title: "O, Me - Gaby Moreno",
    video: "https://www.youtube.com/embed/UIIZU7g8zKw",
    img: gabymoreno,
    cat: "director",
    order: 47,
  },

  {
    title: "Los de Afuera - Al Solo",
    video: "https://www.youtube.com/embed/8V1XFKpF6tw",
    img: losdeafuera,
    cat: "director",
    order: 127,
  },

  {
    title: `Nothing Holding Me Back" - Ana Free y Mechi Pieretti`,
    video: "https://www.youtube.com/embed/lVRJu-Cw4S8",
    img: nothing,

    cat: "director",
    order: 126,
  },

  {
    title: `Wild Thought" - Ana Free y Mechi Pieretti`,
    video: "https://www.youtube.com/embed/AWayTDHxXzQ",
    img: wild,
    cat: "director",
    order: 125,
  },

  {
    title: "En el seno del Amor - Dread Mar I",
    video: "https://www.youtube.com/embed/xRNmYQdUD1o",
    img: enelsenodelamor,
    cat: "director",
    order: 48,
  },

  {
    title: "De Animal - Rodrigo Crespo",
    video: "https://www.youtube.com/embed/8e4XBL4BnT4",
    img: deanimal,
    cat: "director",
    order: 124,
  },

  {
    title: "Corazón - Paola Miranda",
    video: "https://www.youtube.com/embed/E6d29UbtSXU",
    img: corazonpaola,
    cat: "director",
    order: 98,
  },

  {
    title: `Traful - Todo Aparenta Normal"`,
    video: "https://www.youtube.com/embed/JgGhq8voRSc",
    img: traful,
    cat: "director",
    order: 49,
  },

  {
    title: "Tu emblema - Martin Ciolfi",
    video: "https://www.youtube.com/embed/-MomFo14y88",
    img: tuemblema,
    cat: "director",
    order: 50,
  },

  {
    title: "Hola - Il Divo",
    video: "https://www.youtube.com/embed/QsWqIbC7Vk0",
    img: holaildivo,
    cat: "director",
    order: 99,
  },

  {
    title: "Vivir los colores - Todo Aparenta Normal",
    video: "https://www.youtube.com/embed/4E2eoe903fk",
    img: vivirloscolores,
    cat: "director",
    order: 1,
  },

  {
    title: "En el seno del amor (Acústico en vivo) - Dread Mar I",
    video: "https://www.youtube.com/embed/-3uytqE-Hdc",
    img: enelseno,
    cat: "director",
    order: 52,
  },

  {
    title: "Te estás enamorando - Luciano Pereyra, Greeicy",
    video: "https://www.youtube.com/embed/SQUrxpkURvs",
    img: teestasenamorando,
    cat: "director",
    order: 2,
  },

  {
    title: "Sigo en la mía - Colombian Gold",
    video: "https://www.youtube.com/embed/I8ZptEF0cso",
    img: sigoenlamia,
    cat: "director",
    order: 53,
  },

  {
    title: "Encontrarte - Malena Narway",
    video: "https://www.youtube.com/embed/cccwRjJ9Zuw",
    img: encontrarte,
    cat: "director",
    order: 54,
  },

  {
    title: "Les Dos - Nikka Lorach",

    video: "https://www.youtube.com/embed/H8f4SuPGZhQ",
    img: lesdos,
    cat: "director",
    order: 100,
  },

  {
    title: "Locos - Facu Mazzei",
    video: "https://www.youtube.com/embed/jPZFNuPNRdk",
    img: locos,
    cat: "director",
    order: 55,
  },

  {
    title: "Rebelion - Rodrigo Crespo",
    video: "https://www.youtube.com/embed/cdBfGTy1iTo",
    img: rebelion,
    cat: "director",
    order: 56,
  },

  {
    title: "Loop - Ramen",
    video: "https://www.youtube.com/embed/8r6bgOyHUuI",
    img: loop,
    cat: "director",
    order: 57,
  },

  {
    title: "El Temporal - Francisca y Løs Exploradores",
    video: "https://www.youtube.com/embed/e97Wanpgh8A",
    img: eltemporal,

    cat: "director",
    order: 58,
  },

  {
    title: "Solo me importa - Pili Pascual",
    video: "https://www.youtube.com/embed/L7qoTiASNF4",
    img: solomeimporta,
    cat: "director",
    order: 59,
  },

  {
    title: "Mas Humanizado - Todo Aparenta Normal",
    video: "https://www.youtube.com/embed/vfvcHaMwTIU",
    img: mashumanizado,
    cat: "director",
    order: 60,
  },

  {
    title: "Boicot - Ramen",
    video: "https://www.youtube.com/embed/eMBbeIBkvoI",
    img: boicotramen,
    cat: "director",
    order: 61,
  },

  {
    title: "Si lo hacemos los dos - Pili Pascual",
    video: "https://www.youtube.com/embed/hsrxT9XmUTM",
    img: silohacemos,
    cat: "director",
    order: 62,
  },

  {
    title: "La Era de Acuario - Todo Aparenta Normal",
    video: "https://www.youtube.com/embed/2JKXTtnHMCc",
    img: laeradeacuario,
    cat: "director",
    order: 63,
  },

  {
    title: "Guardaré - Pili Pascual",
    video: "https://www.youtube.com/embed/MBUayOcM_Bg",
    img: guardare,
    cat: "director",
    order: 64,
  },

  {
    title: "Local Sounds - Clara Cava",
    video: "https://www.youtube.com/embed/s9pOliJqQIc",
    img: claracava,
    cat: "director",
    order: 101,
  },

  {
    title: "Local Sound presenta - Kastiello",
    video: "https://player.vimeo.com/video/559974123",
    img: kastiello,
    cat: "director",
    order: 102,
  },

  {
    title: "Local Sound presenta - Odd Mami",
    video: "https://www.youtube.com/embed/U5M7MBsg88g",
    img: oddmami,
    cat: "director",
    order: 103,
  },

  {
    title: "Local Sound presenta - Axel Fiks",
    video: "https://player.vimeo.com/video/manage/videos/569834179",
    img: axelfiks,
    cat: "director",
    order: 104,
  },

  {
    title: "Culo - Lola Indigo, KHEA",
    video: "https://www.youtube.com/embed/Xg6BI3SEnfQ",
    img: culo,
    cat: "director",
    order: 3,
  },

  {
    title: "Vlone - Polimá Westcoast ft Khea",
    video: "https://www.youtube.com/embed/kOvrRsagnfc",
    img: vlone,
    cat: "director",
    order: 4,
  },

  {
    title: "Corazon Valiente - Chita",

    video: "https://www.youtube.com/embed/jFkIWYFmvIE",
    img: corazonvaliente,
    cat: "director",
    order: 106,
  },

  {
    title: "Noches Vacias - Natalie Perez",
    video: "https://www.youtube.com/embed/7Rxj15El_X4",
    img: nochesvacias,
    cat: "director",
    order: 107,
  },

  {
    title: "Volverte a Ver - Zoe Gotusso",
    video: "https://www.youtube.com/embed/DvGsRfYxR0I",
    img: volverteaver,
    cat: "director",
    order: 108,
  },

  {
    title: "tercera edición - Gastrojapo ",
    video: "https://www.youtube.com/embed/s5US5TrlYnI",
    img: gastrojapo,
    cat: "director",
    order: 5,
  },

  {
    title: "Se me ha perdido un corazón - Soledad",
    video: "https://www.youtube.com/embed/ZX4hcvk66nw",
    img: nochesvacias,

    cat: "director",
    order: 109,
  },

  {
    title: "Fuiste - Brenda Asnicar Ft. Emme",
    video: "https://www.youtube.com/embed/ZSw63Q3yDcs",
    img: fuiste,
    cat: "director",
    order: 110,
  },

  {
    title: "Gipsy nigth  - Club 69",
    video: "https://player.vimeo.com/video/649764518",
    img: gipsynight,
    cat: "director",
    order: 66,
  },

  {
    title: "Club de la Selva   - Club 69",
    video: "https://player.vimeo.com/video/649770750",
    img: clubdelaselva,
    cat: "director",
    order: 67,
  },

  {
    title: "Pride Day  - Club 69",
    video: "https://player.vimeo.com/video/649773349",
    img: prideday,
    cat: "director",
    order: 6,
  },

  {
    title: "Speed Racer - Club 69",
    video: "https://player.vimeo.com/video/649773543",
    img: speedracer,
    cat: "director",
    order: 68,
  },

  {
    title: "Halloween  - Club 69",
    video: "https://player.vimeo.com/video/649774024",
    img: halloween,
    cat: "director",
    order: 7,
  },

  {
    title: "Cabaret - Club 69",
    video: "https://player.vimeo.com/video/650473616",
    img: cabaret,
    cat: "director",
    order: 69,
  },

  {
    title: "La Trampa - Alejandro Terán",
    video: "https://www.youtube.com/embed/_3mx17Axuc0&t=1s",
    img: latrampa,
    cat: "director",
    order: 8,
  },

  {
    title: "Macumbia - Los Palmeras Feat Neo Pistea",
    video: "https://www.youtube.com/embed/bXKucv8PWuM",
    img: macumbia,
    cat: "director",
    order: 9,
  },

  {
    title: "Dopamina - Alan Sutton",
    video: "https://www.youtube.com/embed/VEx8i2iJqMU",
    img: dopamina,
    cat: "director",
    order: 70,
  },

  {
    title: "Pa' que tenga swing - Los Palmeras Feat Neo Pistea",
    video: "https://www.youtube.com/embed/3jiyWYDQZWU",
    img: paquetenga,
    cat: "director",
    order: 71,
  },

  {
    title: "Si te veo - Stefano Marocco",
    video: "https://www.youtube.com/embed/EUEiOAGUo3w",
    img: siteveo,
    cat: "director",
    order: 10,
  },

  {
    title: "Amame - Los Palmeras ft. Cristian Castro ",

    video: "https://www.youtube.com/embed/32BTVCYaFcs",
    img: amame,
    cat: "director",
    order: 72,
  },

  {
    title: "Fuerza Natural - Visit Ushuaia",
    video: "https://www.youtube.com/embed/2h1uTudwlhM",
    img: fuerzanatural,
    cat: "director",
    order: 11,
  },

  {
    title: "Pendejo - Molotov",
    video: "https://www.youtube.com/embed/TifkUF0jInE",
    img: pendejomolotov,
    cat: "director",
    order: 111,
  },

  {
    title: "Muchachos - la Mosca",
    video: "https://www.youtube.com/embed/i4t1bGD-j9M",
    img: muchachos,
    cat: "director",
    order: 73,
  },

  {
    title: "Tell Me - Moskonys",
    video: "https://www.youtube.com/embed/LAQoey5vYhY",
    img: moskonys,

    cat: "director",
    order: 123,
  },

  {
    title: "Hombre Mujer Bestia - Trailer",
    video: "https://www.youtube.com/embed/E-FCriHQdGc",
    img: hombremujer,
    cat: "director",
    order: 75,
  },

  {
    title: "Vamos Que Nos Vamos - Abraham Mateo, L-Gante, Omar Montes",
    video: "https://www.youtube.com/embed/eM4xPm0PEds",
    img: lgante,
    cat: "director",
    order: 12,
  },

  {
    title: "Ojos Azules - Los Palmeras Ft. Valeria Lynch / Mariano Martínez ",
    video: "https://www.youtube.com/embed/u72ay0x4yC8",
    img: lospalmerasvaleria,
    cat: "director",
    order: 76,
  },

  {
    title:
      "No tengo hambre, tengo ansiedad - Alan Sutton y las criaturitas de la ansiedad",
    video: "https://www.youtube.com/embed/P3AUJSDnLnA",
    img: notengo,
    cat: "director",

    order: 13,
  },

  {
    title: "Ositos Panda - Joze ",
    video: "https://www.youtube.com/embed/h0nhJxup_P4",
    img: ositos,
    cat: "director",
    order: 77,
  },

  {
    title: `"Estamos Listos Para Volver" - Saigón Buenos Aires"`,
    video: "https://www.youtube.com/embed/lSXRBUQ6U5M&ab_channel=TotalMedios",
    img: estamoslistos,
    cat: "director",
    order: 78,
  },

  {
    title: "Tramposa y Mentirosa - Leo Mattioli ft. Rolo Sartorio (La Beriso)",
    video: "https://www.youtube.com/embed/FpPDAPQe_Sk",
    img: tramposa,
    cat: "director",
    order: 112,
  },

  {
    title: "Muerde su labio - Fena Della Maggiora",
    video: "https://www.youtube.com/embed/VCNHBflvXF0",
    img: muerdesulabio,
    cat: "director",
    order: 79,
  },

  {
    title:
      "No Te Puedo Olvidar (Live At Vélez Argentina / 2018)- Luciano Pereyra",
    video: "https://www.youtube.com/embed/_m6qT1PAeQc",
    img: notepuedoolvidar,
    cat: "director",
    order: 113,
  },

  {
    title: "Sale Volando - Cotton, J Mena, Bhavi",
    video: "https://www.youtube.com/embed/OCTrKMtoZW8",
    img: salevolando,
    cat: "director",
    order: 80,
  },

  {
    title: "Inmigrantes - Cenit",
    video: "https://www.youtube.com/embed/xMuwx2zSADM",
    img: cenit,
    cat: "director",
    order: 81,
  },

  {
    title: "Aprenderás a Llorar - Los Palmeras Ft. Los Nocheros",
    video: "https://www.youtube.com/embed/-4YKxtfwRnA",
    img: aprenderas,
    cat: "director",
    order: 114,
  },

  {
    title: "Qué Quiere la Chola - Los Palmeras Ft. La Mosca",
    video: "https://www.youtube.com/embed/Ov-cV4YiKT4",
    img: quequiere,
    cat: "director",
    order: 115,
  },

  {
    title: "La Suavecita - Los Palmeras Ft. Soledad",
    video: "https://www.youtube.com/embed/wA6S57yVqA8",
    img: palmerassoledad,
    cat: "director",
    order: 116,
  },

  {
    title: "El Embrujo - Los Palmeras Ft. Coti",
    video: "https://www.youtube.com/embed/3byNmGMvdQU",
    img: elembrujo,
    cat: "director",
    order: 117,
  },

  {
    title: "Amor - Los Palmeras Ft. Marcela Morello",
    video: "https://www.youtube.com/embed/yl4faTtCZYg",
    img: amor,
    cat: "director",
    order: 118,
  },

  {
    title: "Olvídala - Los Palmeras Ft. Axel",

    video: "https://www.youtube.com/embed/NabLI7HdoLQ",
    img: olvidala,
    cat: "director",
    order: 119,
  },

  {
    title: "Asesina - Los Palmeras Ft. Andrés Calamaro",
    video: "https://www.youtube.com/embed/aCyga__HDPY",
    img: asesina,
    cat: "director",
    order: 14,
  },

  {
    title: "Mueve - Platino",
    video: "https://www.youtube.com/embed/k5IXHLc04Og",
    img: platinomueve,
    cat: "director",
    order: 122,
  },

  {
    title: "Adicto a tu amor - Maná",
    video: "https://www.youtube.com/embed/KmtfNSg7QY4",
    img: mana,
    cat: "director",
    order: 15,
  },

  {
    title: "Las luces de mi edad - Patanes ",
    video: "https://www.youtube.com/embed/3Rp9ySQApwM",
    img: lasluces,

    cat: "director",
    order: 121,
  },

  {
    title: "Y deshacer - Marilina Bertoldi",
    video: "https://www.youtube.com/embed/KKzWeuO591U",
    img: ydeshacer,
    cat: "director",
    order: 16,
  },
];

export default videos;

console.log(
  "ramon",
  videos.filter((video) => video.cat === "editor")
);
